import React from 'react';
import './Pagination.scss'

function Pagination({
  numpages,
  selectedPage,
  pageSizeList,
  pageNumberChange,
  pageSizeChange,
}) {
  function pagenations() {
    const pages = Array.from({ length: numpages }, (_, index) => index + 1);
    return (
      <>
        {pages &&
          pages.map((item, _) => (
            <div className={item===selectedPage?`page__item__box selected__page__box`:`page__item__box`} onClick={() => pageNumberChange(item)}>
              <span >{item}</span>
            </div>
          ))}
      </>
    );
  }

  function handlePrevPage() {
    pageNumberChange(selectedPage - 1);
  }
  function handleNextPage() {
    pageNumberChange(selectedPage + 1);
  }
  function handlePageSize(e) {
    pageSizeChange(Number(e.target.value));
  }
  return (
    <>
      <div className='pagination__conatiner__section'>
        <div className='pages__section'>
          <div className='page__item__box'>
            <span className='prevPage' onClick={() => handlePrevPage()}>prev</span>
          </div>
          <div className='pageNumber'>{pagenations()}</div>
          <div className='page__item__box'>
            <span className='nextPage' onClick={() => handleNextPage()}>next</span>
          </div>
        </div>
        <div className='options__list__section'>
          <select name='pageSizeList' id='pageSizeList' className='optionsList' onChange={handlePageSize}>
            {pageSizeList.map((item, idx) => (
              <option value={item} >
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default Pagination;
