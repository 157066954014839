import axios from "axios";

// get login form
const getForm = (param) => {
  return axios({
    method: "get",
    url: "/user/form/" + param + "/" + param,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const authenticate = (params) => {
  let data = params;
  return axios({
    method: "post",
    url: "/user/authenticate",
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    credentials: "include",
    data: data,
  });
};

const authValidate = (params) => {
  return axios({
    method: "get",
    url: "/user/validate",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const authCheck = (params) => {
  return axios({
    method: "get",
    url: "/user/authCheck",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const emailVerification = (params) => {
  return axios({
    method: "post",
    url: "/user/email-verification",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const checkSignUpLinkValidity = (params) => {
  return axios({
    method: "post",
    url: "/user/checkSetPassoword",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const checkTermsAndConditionLinkValidity = (params) => {
  return axios({
    method: "post",
    url: "/user/checkTermsAndCondition",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const signUp = (params) => {
  return axios({
    method: "post",
    url: "/user/signup",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const signUpWithGoogle = (params) => {
  return axios({
    method: "post",
    url: "/user/signUpWithGoogle",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const logIn = (params) => {
  return axios({
    method: "post",
    url: "/user/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const forgotPassword = (params) => {
  return axios({
    method: "post",
    url: "/user/forgotPassword",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const checkForgotPasswordLinkValidity = (params) => {
  return axios({
    method: "post",
    url: "/user/checkForgotPasswordLinkValidity",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const updatePassword = (params) => {
  return axios({
    method: "post",
    url: "/user/updatePassword",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const changePassword = (params) => {
  return axios({
    method: "post",
    url: "/user/changePassword",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const logout = (params) => {
  sessionStorage.clear();
  return axios({
    method: "get",
    url: "/user/logout",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getNav = (params) => {
  return axios({
    method: "get",
    url: "/site/navigation",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getHeader = (params) => {
  let url = params ? "/site/header/" + params : "/site/header";
  return axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getFooter = (params) => {
  let url = params ? "/site/footer/" + params : "/site/footer";
  return axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getBanners = (params) => {
  let url = params ? "/site/banners/" + params : "/site/banners";
  return axios({
    method: "get",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const updateProfile = (params) => {
  return axios({
    method: "put",
    url: "/user/updateProfile",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const getProfile = (params) => {
  return axios({
    method: "get",
    url: "/user/getProfile",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getProfileById = (id) => {
  return axios({
    method: "get",
    url: "/user/getProfileById/" + id,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getAccount = (params) => {
  return axios({
    method: "get",
    url: "/user/getAccount",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const addCourse = (params) => {
  return axios({
    method: "put",
    url: "/user/addcourse",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const getLearnerCourseList = (params) => {
  return axios({
    method: "get",
    url: "/user/getLearnerCourseList",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getLearnerPathwayList = (params) => {
  return axios({
    method: "get",
    url: "/user/getLearnerPathwayList",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const addToFavourite = (params) => {
  return axios({
    method: "put",
    url: "/user/addToFavourite",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

/**
 * Files
 */
// G-Drive
const fileUpload = (params) => {
  return axios({
    method: "post",
    url: "/site/fileUpload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
};
const fileRemove = (params) => {
  return axios({
    method: "delete",
    url: "/site/deleteFile/" + params.name,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getFileByName = (params) => {
  return "/site/getFile/" + params;
};
const getFileFromDrive = (params) => {
  return axios({
    method: "get",
    url: "/site/get_File/" + params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const deleteFileGdrive = (params) => {
  return axios({
    method: "delete",
    url: "/site/deleteFileGdrive/" + params.name,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// MongoDB
const fileUpload_md = (params) => {
  return axios({
    method: "post",
    url: "/site/fileUpload_md",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
};

/**
 * Course
 */
const createCourse = (params) => {
  return axios({
    method: "post",
    url: "/user/createCourse",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const getMyCourses = (params) => {
  return axios({
    method: "post",
    url: "/user/getMyCourses",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const deleteCourse = (params) => {
  console.log("params", params);
  return axios({
    method: "post",
    url: "/user/deleteCourse",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const courseDetails = (params) => {
  return axios({
    method: "post",
    url: "/user/courseDetails",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const myCourseDetails = (params) => {
  //{courseId:courseId}
  return axios({
    method: "post",
    url: "/user/myCourseDetails",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const learnerCourseDetails = (params) => {
  //{courseId:courseId}
  return axios({
    method: "post",
    url: "/user/learnerCourseDetails",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const courseSearch = (params) => {
  return axios({
    method: "post",
    url: "/user/courseSearch",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const updateCourse = (params) => {
  return axios({
    method: "put",
    url: "/user/editCourse/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const updateCourses = (params) => {
  return axios({
    method: "post",
    url: "/user/updateCourses",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const addChapter = (params) => {
  return axios({
    method: "post",
    url: "/user/addChapter/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const updateChapter = (params) => {
  return axios({
    method: "put",
    url: "/user/updateChapter/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

const arrangeChapter = (params) => {
  return axios({
    method: "put",
    url: "/user/arrangeChapter/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

const removeChapter = (params) => {
  return axios({
    method: "put",
    url: "/user/removeChapter/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

const addVideo = (params) => {
  return axios({
    method: "post",
    url: "/user/addVideo/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const updateVideo = (params) => {
  return axios({
    method: "put",
    url: "/user/updateVideo/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const removeVideo = (params) => {
  return axios({
    method: "put",
    url: "/user/removeVideo/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

const getPathwayById = (params) => {
  return axios({
    method: "get",
    url: "/user/getPathwayById/" + params.id,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const createIcs = (params) => {
  return axios({
    method: "post",
    url: "/user/createIcs",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

const addCourseDocument = (params) => {
  return axios({
    method: "put",
    url: "/user/addCourseDocument/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const removeCourseDocument = (params) => {
  return axios({
    method: "put",
    url: "/user/removeCourseDocument/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

// deprecated
const updateContent = (params) => {
  return axios({
    method: "post",
    url: "/user/updateContent/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};
const removeContent = (params) => {
  return axios({
    method: "put",
    url: "/user/removeContent/" + params.courseId,
    headers: {
      "Content-Type": "application/json",
    },
    data: params.data,
  });
};

const addMultiCourses = (params) => {
  return axios({
    method: "post",
    url: "/user/addMultiCourses",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
const requestAuthor = (params) => {
  return axios({
    method: "post",
    url: "/user/requestAuthor",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};

// vimeo
const videoUpload = (params) => {
  return axios({
    method: "post",
    url: "/site/videoUpload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
};
/**
 * req body
 *  {vid: {video_name}}
 */
const getVideoLink = (params) => {
  return axios({
    method: "post",
    url: "/site/getVideoLink",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
// checkTranscoding
const checkTranscoding = (params) => {
  return axios({
    method: "post",
    url: "/site/checkTranscoding",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
/**
 * req body
 *  {vid: {id}}
 */
const deleteVimeoVideo = (params) => {
  return axios({
    method: "delete",
    url: `https://api.vimeo.com/videos/${params.vid}`,
    headers: {
      Authorization: "Bearer d91122f03850b07b148ab488df5b6f4b",
    },
  });
};

// category
const getCategories = (params) => {
  return axios({
    method: "get",
    url: "/user/getCategories",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getSubCategories = (params) => {
  return axios({
    method: "get",
    url: "/user/getSubCategories/" + params.id,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// Rating
// course Rating
const addRating = (params) => {
  return axios({
    method: "post",
    url: "/user/addRating/",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};
// Author Rating
const addAuthorRating = (params) => {
  return axios({
    method: "post",
    url: "/user/addAuthorRating/",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
};


// Cloudinary imageupload
const imageupload = (params) => {
  return axios({
    method: "post",
    url: "/site/imageupload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
};
const docupload = (params) => {
  return axios({
    method: "post",
    url: "/site/docupload",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: params,
  });
};

const actionPlan = (params) => {
  return axios({
    method: "post",
    url: "/user/actionPlan",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
}

const actionPlanFlagChange = (courseId) => {
  return axios({
    method: "put",
    url: "/user/actionPlanFlagChange/" + courseId,
    headers: {
      "Content-Type": "application/json",
    }
  });
}

const finalAssessmentUpdate = (params) => {
  return axios({
    method: "put",
    url: "/user/finalAssessmentUpdate",
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  });
}
const removeQuiz = params => {
  let data = params;
  return axios({
    method: "post",
    url: '/user/removeQuiz/' + data.courseId,
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true,
    credentials: 'include',
    data: data.reqObj
  });
}

const addQuiz = params => {
  let data = params;
  return axios({
      method: "put",
      url: '/user/addQuiz/'+data.courseId,
      headers: {
          "Content-Type": "application/json"
      },
      withCredentials: true,
      credentials: 'include',
      data: data.reqObj
  });
}
const updateQuiz = params => {
  let data = params;
  return axios({
      method: "put",
      url: '/user/updateQuiz/'+data.courseId,
      headers: {
          "Content-Type": "application/json"
      },
      withCredentials: true,
      credentials: 'include',
      data: data.reqObj
  });
}
const finalAssesment = params => {
  let data = params;
  return axios({
      method: "put",
      url: '/user/finalAssesment/'+data.courseId,
      headers: {
          "Content-Type": "application/json"
      },
      withCredentials: true,
      credentials: 'include',
      data: data.reqObj
  });
}


export default {
  // ------- auths ----
  authValidate: authValidate,
  getForm: getForm,
  authenticate: authenticate,
  emailVerification: emailVerification,
  checkSignUpLinkValidity: checkSignUpLinkValidity,
  checkTermsAndConditionLinkValidity: checkTermsAndConditionLinkValidity,
  signUp: signUp,
  signUpWithGoogle: signUpWithGoogle,
  logIn: logIn,
  forgotPassword: forgotPassword,
  checkForgotPasswordLinkValidity: checkForgotPasswordLinkValidity,
  updatePassword: updatePassword,
  changePassword: changePassword,
  getProfile: getProfile,
  getAccount: getAccount,
  getProfileById: getProfileById,
  logout: logout,
  addCourse: addCourse,
  getLearnerCourseList: getLearnerCourseList,
  getLearnerPathwayList: getLearnerPathwayList,
  authCheck: authCheck,
  addToFavourite: addToFavourite,
  // ------- site ------
  getHeader: getHeader,
  getFooter: getFooter,
  getBanners: getBanners,
  getNav: getNav,
  updateProfile: updateProfile,
  // ------- files -----
  // Gdrive
  fileUpload: fileUpload,
  getFileByName: getFileByName,
  getFileFromDrive: getFileFromDrive,
  fileRemove: fileRemove,
  deleteFileGdrive: deleteFileGdrive,
  // MongoDb
  fileUpload_md: fileUpload_md,
  //-------- course---
  createCourse: createCourse,
  deleteCourse: deleteCourse,
  courseDetails: courseDetails,
  courseSearch: courseSearch,
  updateCourse: updateCourse,
  getMyCourses: getMyCourses,
  myCourseDetails: myCourseDetails,
  learnerCourseDetails: learnerCourseDetails,
  updateCourses: updateCourses,
  addMultiCourses: addMultiCourses,
  addChapter: addChapter,
  removeChapter: removeChapter,
  updateChapter: updateChapter,
  arrangeChapter: arrangeChapter,
  getCategories: getCategories,
  getSubCategories: getSubCategories,
  addCourseDocument: addCourseDocument,
  removeCourseDocument: removeCourseDocument,
  actionPlanFlagChange: actionPlanFlagChange,
  finalAssessmentUpdate: finalAssessmentUpdate,

  addVideo: addVideo,
  updateVideo: updateVideo,
  removeVideo: removeVideo,
  requestAuthor: requestAuthor,
  addRating: addRating,
  addAuthorRating: addAuthorRating,
  actionPlan: actionPlan,

  // vimeo
  videoUpload: videoUpload,
  getVideoLink: getVideoLink,
  deleteVimeoVideo: deleteVimeoVideo,
  checkTranscoding: checkTranscoding,

  // Cloudinary
  imageupload: imageupload,
  docupload: docupload,

  // deprecated
  removeContent: removeContent,
  updateContent: updateContent,
  createIcs: createIcs,
  getPathwayById: getPathwayById,

  // quiz
  removeQuiz: removeQuiz,
  addQuiz:addQuiz,
  updateQuiz:updateQuiz,
  finalAssesment:finalAssesment,
};
