import React, { Component } from 'react';
import './contactus.scss';

class Contact extends Component {
    render() {
        return (
            <div className="page-content contactUs">
                <div class="fluid-banner"><h1>Contact Us </h1></div>
                <div className="container inner-content">
                    <h4><strong>Accelerated Learning Technologies</strong></h4>
                    <div className='contactPoint'>
                        <div className='contactEW'>
                            <div className='contactP contactEmail'>
                                <span><i class="bi bi-envelope"></i></span>
                                <h5>adith@axllearn.com</h5>
                            </div>
                            <div className='contactP contactWhtsp'>
                                <span><i class="bi bi-whatsapp"></i></span>
                                <h5>+91 94491 50110</h5>
                            </div>
                        </div>
                    </div>
                    <div className='contactAddresses'>
                    <h4><strong>Our Office Address</strong></h4>
                        <div className='contactAddressLoc'>
                            <div className='addlocation'>
                                <h5>India</h5>
                                <h6>43/4, Davis Road, Richards Town, Bangalore 560084</h6>
                            </div>
                            <div className='addlocation'>
                                <h5>USA</h5>
                                <h6>Pennsylvania</h6>
                            </div>
                            <div className='addlocation'>
                                <h5>UAE</h5>
                                <h6>Business Centre, Dubai South, Dubai World Central, Dubai</h6>
                            </div>
                            <div className='addlocation'>
                                <h5>Canada</h5>
                                <h6>Toronto</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;