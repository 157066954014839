import React from 'react';
import './certificate.scss';
import AwardImg from '../../../../assets/images/trophy.png';
import AxlIconImg from '../../../../assets/images/axl-icon.png';
import { useLocation,useHistory } from 'react-router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const Certificate = () => {
    const location = useLocation();
    const history = useHistory();

    const courseName = location.state?.courseName;
    const userName = location.state?.userName;
    const totalDuration = location.state?.totalDuration;
    const endDate=location.state?.endDate

    function printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, 208, 156);
                pdf.save("Certificate.pdf");
            })
            ;
    }

    return (
        <React.Fragment>
            <div className="page-content certificate">
            <div className="back-button">
                <button type='button' onClick={()=>history.goBack()} className='btn btn-primary'>Back</button>
                </div>
                <div className="axl-certificate">
                    <div id="divToPrint">
                        <div className='axl-certificate-inn'>
                            <div className='axl-logo'>
                                <img src={AxlIconImg} alt="///"></img>
                            </div>
                            <div className='certificate-content'>
                                <h2>Certificate <span>Of Completion</span></h2>
                                <div className='thisawarded'>
                                    <h4><span className='bg-effects'>This is awarded to</span></h4>
                                </div>
                                <div className='learners'>
                                    <h2>{userName}</h2>
                                    <p>for completing the course <span>{courseName}</span> by Axllearn.</p>
                                    <h6>you have completed this course by duration of {totalDuration}</h6>
                                </div>
                                <p className='end__date'>{endDate}</p>
                            </div>
                            <div className='certificate-footer'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <div className='cf-text'>
                                            <span className='cf-text-inn'>
                                                {/* <p>Morgan Maxwell</p>
                                                <h4>Head Manager</h4> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='award-img'><img src={AwardImg} alt="///"></img></div>
                                    </div>
                                    <div className='col-4'>
                                        <div className='cf-text'>
                                            <span className='cf-text-inn'>
                                                {/* <p>David Miller</p>
                                                <h4>Course Author</h4> */}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="axl-certificate-download"> <button onClick={printDocument} className="btn btn-primary btn-certificate-download"><i class="bi bi-download"></i> Download</button></div>
            </div>
        </React.Fragment>
    )
}

export default Certificate



