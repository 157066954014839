export const INITIAL_STATE={
    loader: true,
    resMessage: '',
    resType: '',
    resTitle: '',
    resCode: '',
    currentTab: "about",
    course: null,
    info: {},
    currentChapter: '',
    currentLecture: '',
    quizQuestions: [],
    finalAssesmentPage: false,
    currentQuizIndex: 0,
    score: 0,
    submitPage: null,
    userAnswer: null,
    quizPageOpen: true,
    videoPageOpen: null,
    finalQuiz: [],
    checkedAns: false,
    chapterSubmitPage: null,
    coursePlayer: [],
    store_count: 0,
    learner_courses: [],
    chapterClick: "",
    category: '',
    subcategory: '',
    video_name: null,
    curr_content: {},
    current_chapter_id:'',
    isVideo: true,
    courseCompletion: 0,
    isFinalAssessmentCompleted:false,
    actionPlanFlag: true,
    displayRatingSection: false,
    displayCourseCompletionModal:true,
    finalAssessmentScore:0,
    finalAssesmentAnswerIdx:{},
    courseRetakePopUpFlag:true,
    showActionPlanNotificationDialog:false,
}


//RETAKE COURSE FUNCTIONALITY
export const retakeCourseFormatting=(courseDetails,faAnswerIdx,courseID)=>{
    let update_learner=courseDetails 
    let finalAssesmentAnswerIdx={}
    Object.keys(faAnswerIdx).map((_,idx) =>finalAssesmentAnswerIdx[idx]=[])
    update_learner.forEach((elem, ei) => {
        if (elem.id === courseID) {
            elem.actionPlanFlag=true;
            elem.finalAssessmentScore=0;
            elem.isFinalAssessmentCompleted=false;
            elem.finalAssesmentAnswerIdx=finalAssesmentAnswerIdx;
            elem.status.forEach((s_elem, si) => {
                    s_elem.progress.forEach((pr_elem, pi) => {
                     pr_elem.completed=0;
                     if(pr_elem.hasOwnProperty('score')) pr_elem.score=0;
                     if(pr_elem.hasOwnProperty('quizAnswerIdx')) {
                         let obj={};
                        Object.keys(pr_elem.quizAnswerIdx).forEach((_,i)=>obj[i]=[])
                        pr_elem.quizAnswerIdx=obj;
                     }
                    });    
            });
        };
    });

    return update_learner;
}


//GET COURSE DETAILS FUNCTIONALITY
export const getCourseDetailsFormatting=(res,courseID)=>{
    let finalAssesmentPage=false;
    let actionPlanFlag = true;
    let isFinalAssessmentCompleted=false;
    let finalAssessmentScore=0;
    let currentCourse;
    let finalAssesmentAnswerIdx={}
    let total_chapter = res.data.data.content.chapters;
    let topic_wise = [];
    let currentLoadChapterIndex=0;
    let currentLoadTopicIndex=0;
  
    for (let i = 0; i < res.data.usersCourses.length; i++) {
        if (res.data.usersCourses[i].id === courseID) {
            currentCourse=res.data.usersCourses[i];
            actionPlanFlag = res.data.usersCourses[i]?.actionPlanFlag;
            isFinalAssessmentCompleted = res.data.usersCourses[i]?.isFinalAssessmentCompleted;
            finalAssesmentAnswerIdx = res.data.usersCourses[i]?.finalAssesmentAnswerIdx; 
            finalAssessmentScore = res.data.usersCourses[i]?.finalAssessmentScore;
            break;
        }
    }

    for (let i = 0; i < total_chapter.length; i++) {
        let is_section_completed=true;
        let current_topic = { ...total_chapter[i] };
        let new_topic = {
            title: current_topic.title,
            id: current_topic.id,
            contents: (() => {
                let local_arr = [...current_topic.videos].sort((a, b) => a.position - b.position);

                local_arr.forEach((vi,idx) => {
                    const {completion,completed}=getInitialCompletionStatus(currentCourse,local_arr[idx].name,current_topic.id,'video');
                    vi.type = "video";
                    vi.completion =completion;
                    vi.completed=completed;
                    vi.score=null;
                    vi.quizAnswerIdx=null;
                    if(completion<100 && vi.type === "video"){
                        is_section_completed=false;
                        // currentLoadChapterIndex=i;
                        // currentLoadTopicIndex=vi.position;
                    }
                })

                if (current_topic.quizzes && current_topic.quizzes.length > 0) {
                    current_topic.quizzes.forEach(qitem => {
                        const{completion,completed,score,quizAnswerIdx}=getInitialCompletionStatus(currentCourse,qitem.name,current_topic.id,'quiz');
                        let formattedItem=qitem;
                        formattedItem.completion=completion;
                        formattedItem.completed=completed;
                        formattedItem.score=score;
                        formattedItem.quizAnswerIdx=quizAnswerIdx;
                        local_arr.splice(qitem.position, 0, qitem)
                        if(completed!==100)
                        is_section_completed=false;
                        // if(Object.keys(this.state.curr_content).length !== 0 && this.state.curr_content.name===qitem.name && completed===100){
                        // currentLoadChapterIndex=i;
                        // currentLoadTopicIndex=qitem.position;
                        // }
                    })
                }
                if (current_topic.quiz) {

                    local_arr.push({
                        type: "quiz",
                        title: "Quiz: " + current_topic.title,
                        values: current_topic.quiz,
                        name: current_topic.id
                    })
                }
                return local_arr;
            })(),
            is_section_completed
        }
        topic_wise.push(new_topic);
    }

    for(let i=0;i<topic_wise.length;i++){
     if(!topic_wise[i].is_section_completed){
         if(i===0 || (topic_wise[i-1].is_section_completed)){
           for(let j=0;j<(topic_wise[i].contents).length;j++){
               if(topic_wise[i].contents[j].completion<100){
                currentLoadChapterIndex=i;
                currentLoadTopicIndex=j;
                break;
               }
           }
         }
     }
    }
   
   //If All Chapters Are completed Completed Load Final Assessment
    if(topic_wise[topic_wise.length-1].is_section_completed){
        finalAssesmentPage=true;
    }

    return {
         finalAssesmentPage,
         actionPlanFlag,
         isFinalAssessmentCompleted,
         finalAssessmentScore,
         currentCourse,
         finalAssesmentAnswerIdx,
         total_chapter,
         topic_wise,
         currentLoadChapterIndex,
         currentLoadTopicIndex
    }
}

export const getInitialCompletionStatus=(currentCourse,currentChapterName,currentTopicId,type)=>{
    let completion;
    let completed;
    let score=null;
    let quizAnswerIdx=null
    currentCourse.status.forEach((s_elem, si) => {
        if (s_elem.chapterId === currentTopicId) {

            s_elem.progress.forEach((pr_elem, pi) => {
                if (pr_elem.name === currentChapterName) {
                    if(type==='video'){

                        completion= Math.ceil((pr_elem.completed / pr_elem.duration) * 100)
                        completed=Math.ceil(pr_elem.completed)
                    }
                    else{
                        completion=pr_elem.completed;
                        completed=pr_elem.completed;
                        score=pr_elem.score;
                        quizAnswerIdx=pr_elem.quizAnswerIdx;
                    }
                };
               
            });
        };
    });
    return {completion,completed,score,quizAnswerIdx}
}