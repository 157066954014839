import React, { Component } from 'react';
import api from '../../../../services/Api/Api';
import './profile.scss';
import { Multiselect } from 'multiselect-react-dropdown';

import { Modal } from '../../../shared/modal/modal';
import Loader from '../../../shared/loader/loader';
import { v4 as uuidv4 } from 'uuid';


class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            name: '',
            formdata: [],
            email: '',
            profileImage: '',
            resMessage: '',
            resType: '',
            reCall: '',
            // ----
            options: [
                { name: 'Software', id: 1 },
                { name: 'Leadership', id: 2 },
                { name: 'Personal Development', id: 2 },
                { name: 'Python', id: 2 },
                { name: 'Comunication Skills', id: 2 },
                { name: 'Front End Development', id: 2 }
            ],
            selectedValue: [],
            preference: [],
            isProfileEdit: false,
            socialLinks: [],
            isGoogleSignin: null

        }

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() {
        this.getProfileForm();
    }

    getProfileForm() {
        api.getForm('profile').then(res => {
            if (res.data.resCode === 200) {
                this.setState({
                    loader: false,
                    formdata: res.data.data.fields,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType
                }, () => {
                    console.log('formdata', this.state.formdata);
                    this.getProfile();
                })
            } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                this.setState({
                    loader: false,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    reCall: 'getProfileForm'
                })
            }
            if (res.data.resCode === 400) {
                api.logout().then(res => {
                    window.location.replace('/login')
                })
            }
        }).catch(err => {
            this.setState({
                loader: false,
                resMessage: "Something went wrong! Please try again..",
                resType: 'danger'
            })
        })
    }

    getProfile() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getProfile().then(res => {
                if (res.data.resCode === 200) {
                    this.setState(prevState => ({
                        ...prevState,
                        loader: false,
                        isGoogleSignin: res.data.data.isGoogleSignin,
                        profileImage: res.data.data.profile.profilePic,
                        email: res.data.data.email,
                        name: res.data.data.profile.firstName + ' ' + res.data.data.profile.lastName,

                        userType: res.data.data.profile.userType,

                        socialLinks: res.data.data.profile.social,
                        selectedValue: (() => {
                            let local_arr = [];
                            res.data.data.profile.preferences.map(item => {
                                let index = prevState.options.map(op => { return op.name }).indexOf(item);
                                if (index > -1) {
                                    local_arr.push(prevState.options[index])
                                }
                            });
                            return local_arr;
                        })(),
                        preference: res.data.data.profile.preferences,
                        formdata: prevState.formdata.map(
                            obj => Object.assign(obj, {
                                value: res.data.data.profile[obj.name]
                            })),
                    }), () => {
                        console.log('this.state', api.getFileFromDrive(this.state.profileImage));
                        this.props.profile({
                            profilePic: res.data.data.profile.profilePic,
                            firstName: res.data.data.profile.firstName ? res.data.data.profile.firstName : res.data.data.email,
                            lastName: res.data.data.profile.lastName ? res.data.data.profile.lastName : ''
                        });
                    })
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
            }).catch(err => {

                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        });

    }

    handleFormChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            formdata: prevState.formdata.map(
                obj => (obj.name === name ? Object.assign(obj, {
                    value: value,
                    error: (() => {
                        if ((obj.validation.isEmptyString && value === '') || (obj.validation.isUndefined && !value) || (obj.validation.isRegex && !this.testRegex(obj.regex, value))) return true;
                        return false;
                    })()
                }) : obj)
            )
        }), () => {
            console.log('this.state', this.state);
        })
    }

    testRegex = (reg, val) => {
        let regx = new RegExp(reg, 'i');
        return regx.test(val);
    }

    onSelect(selectedList, selectedItem) {
        let onlyItemName = []
        selectedList.forEach(item => {
            onlyItemName.push(item.name)
        });
        this.setState({
            preference: onlyItemName
        })
    }

    onRemove(selectedList, removedItem) {
        let onlyItemName = []
        selectedList.forEach(item => {
            onlyItemName.push(item.name)
        });
        this.setState({
            preference: onlyItemName
        })
    }

    fileUpload(event) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.fileUpload(formData).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        profileImage: res.data.data.id,
                        loader: false
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                console.log('fileupload err in profile', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        });
    }

    updateProfile(e) {
        if (e) {
            e.preventDefault();
        }
        let isError = false;
        let profileData = {
            profilePic: this.state.profileImage,
            userType: this.state.userType
        };
        this.state.formdata[this.state.formdata.map(e => { return e.name }).indexOf('preferences')].value = this.state.preference;
        this.state.formdata[this.state.formdata.map(e => { return e.name }).indexOf('social')].value = this.state.socialLinks;

        // for(var i = 0; i <this.state.formdata.length; i++){
        //     this.state.formdata[i]
        // }
        this.state.formdata.map((item) => {
            if (item.error) {
                isError = true;

            }
            profileData[item.name] = item.value;
        });
        if (isError === true) {
            this.setState({
                resMessage: "Please fill up the form correctly, before you submit",
                resType: "danger"
            });
            return;
        }
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.updateProfile({ profile: profileData }).then(res => {

                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        isProfileEdit: false
                    })
                    this.props.profile(profileData);
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })
    }

    dummyFunc() {
        // do nothing
    }
    getMedia(val, stateId) {
        // console.log('val', val);
        api.getFileFromDrive(val).then(res => {
            if (!document.getElementById(stateId).src) {
                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    // document.getElementById(stateId).parentNode.className = document.getElementById(stateId).parentNode.className + ' bg-primary text-light';
                    // document.getElementById(stateId).parentNode.setAttribute("data-name", this.state.name.charAt(0) + this.state.name.split(' ')[this.state.name.split(' ').length - 1].charAt(0));
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            // console.log('loader err of ' + stateId, ': ', err);
            // console.log('document.getElementById(stateId)', document.getElementById(stateId));
            // if (!document.getElementById(stateId).src) {
            //     document.getElementById(stateId).src = ''
            //     // document.getElementById(stateId).parentNode.className = document.getElementById(stateId).parentNode.className + ' bg-primary text-light';
            //     // document.getElementById(stateId).parentNode.setAttribute("data-name", this.state.name.charAt(0) + this.state.name.split(' ')[this.state.name.split(' ').length - 1].charAt(0));
            //     document.getElementById(stateId).style.opacity = 0;
            // }
        })
    }

    deleteFile(val) {
        api.deleteFileGdrive({ name: val }).then(res => {

            this.setState({
                profileImage: ''
            }, () => {
                this.updateProfile()
            })

        }).catch(err => {

        })
    }

    onClickChangePassword() {
        // window.location.replace('/changePassword')
        window.open('/changePassword', '_blank', 'noopener,noreferrer');
    }
    render() {
        const apiDialog = this.state.resMessage !== '' ? <Modal>
            <div className={`text-center alert alert-${this.state.resType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
                <p>{this.state.resMessage}</p>
                <br />
                <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '' }) }}>Okay</button>
            </div>
        </Modal> : null;

        const loader = this.state.loader ? <Modal>
            <Loader message={"Loading..."} />
        </Modal> : null;
        return (
            <React.Fragment>
                {!this.state.loader &&

                    <div className="profile-right-inner">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="page-title">
                                        <h2>User’s Profile <small className="text-primary">({this.state.email})</small></h2>
                                        <h6>Add or Edit information about yourself</h6>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="btn-block">
                                        {!this.state.isGoogleSignin && <button type="button" className="btn btn-highlight-secondary" onClick={() => this.onClickChangePassword()} > Change Password</button>}

                                        <button type="button" className="btn btn-highlight-secondary" onClick={() => { this.setState({ isProfileEdit: !this.state.isProfileEdit }); this.state.isProfileEdit ? this.getProfile() : this.dummyFunc() }}>
                                            {!this.state.isProfileEdit ?
                                                <><i className={`bi bi-pencil-square`}></i> Edit Profile</> : <><i className={`bi bi-x-square`}></i> Cancel</>
                                            }
                                        </button>
                                        <button type="button" className="btn btn-highlight-secondary" disabled={!this.state.isProfileEdit} onClick={(e) => this.updateProfile(e)}><i className="bi bi-save"></i> Save Updates</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="general-info">
                                        <h4>General Information</h4>
                                        <form onSubmit={(e) => this.updateProfile(e)}>
                                            {this.state.formdata.length > 0 &&
                                                <>
                                                    {this.state.formdata.map((item, i) => {
                                                        return <div key={i} className={`form-group ${item.type === 'radio' ? 'radio-block' : ''}`}>
                                                            {/* <span className="input-group-text"><i className={`${item.icon} ${(!this.testRegex(item.regex, item.value) && item.value !== '') || item.error ? 'text-danger' : ''}`}></i></span> */}
                                                            {item.type === 'text' &&
                                                                <input type={item.type} disabled={!this.state.isProfileEdit} className={`form-control ${item.error ? 'border-danger' : ''}`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                            }
                                                            {item.type === 'textarea' &&
                                                                <textarea maxLength={500} type={item.type} disabled={!this.state.isProfileEdit} className={`form-control`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value}
                                                                    onChange={this.handleFormChange}
                                                                />
                                                            }
                                                            {item.type === 'radio' &&
                                                                <>
                                                                    <div className="radio-text">
                                                                        <label className="form-label">{item.label}</label>
                                                                    </div>
                                                                    {item.options.map((option, oi) => {
                                                                        return <div key={i + '-' + oi} className="form-check">
                                                                            <input className="form-check-input" disabled={!this.state.isProfileEdit} type={item.type} value={option.value} name={item.name} id={option.value}
                                                                                checked={item.value === option.value} onChange={this.handleFormChange} />
                                                                            <label className="form-check-label" htmlFor={option.value}>{option.label}</label>
                                                                        </div>
                                                                    })}
                                                                </>
                                                            }
                                                            {item.type === 'select' &&
                                                                <>
                                                                    <div className="radio-text">
                                                                        <label className="form-label">{item.label}</label>
                                                                    </div>
                                                                    {this.state.isProfileEdit &&
                                                                        <>
                                                                            <Multiselect disabled={!this.state.isProfileEdit} options={this.state.options} selectedValues={this.state.selectedValue} displayValue="name" onSelect={(list, item) => this.onSelect(list, item)} onRemove={this.onRemove} />
                                                                        </>
                                                                    }
                                                                    {!this.state.isProfileEdit &&
                                                                        <>
                                                                            {this.state.selectedValue.map(item => {
                                                                                return <span key={uuidv4()} className={`badge bg-primary p-1 m-1 rounded-pill`}>{item.name}</span>
                                                                            })}
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                            {item.type === 'social' &&
                                                                <>

                                                                    {item.options.map((option, oi) => {
                                                                        return <div key={i + '-' + oi}>
                                                                            <div className="input-group mb-3">
                                                                                <span className="input-group-text" ><i className={`${option.icon} text-primary`}></i></span>
                                                                                <input type="text" className="form-control" placeholder={option.label}
                                                                                    defaultValue={
                                                                                        this.state.socialLinks.map(si => { return si.name }).indexOf(option.label) === -1 ? option.value :
                                                                                            this.state.socialLinks[this.state.socialLinks.map(si => { return si.name }).indexOf(option.label)].value}
                                                                                    name={option.label} id={option.value}
                                                                                    onChange={(e) => {
                                                                                        let slinks = [...this.state.socialLinks];
                                                                                        let s_link_index = slinks.map(item => { return item.name }).indexOf(e.target.name);
                                                                                        if (s_link_index === -1) {
                                                                                            slinks.push({ name: e.target.name, value: e.target.value })
                                                                                        } else {
                                                                                            slinks[s_link_index].value = e.target.value;
                                                                                        }
                                                                                        this.setState(prev => ({
                                                                                            socialLinks: slinks
                                                                                        }), () => {
                                                                                            console.log('this.state', this.state)
                                                                                        })
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                        </div>
                                                                    })}
                                                                </>
                                                            }

                                                        </div>
                                                    })}
                                                </>
                                            }

                                        </form>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="profile-photo">
                                        <h4>Profile Photo</h4>
                                        <div className="photo-preview">
                                            <div className="img-block">
                                                {/* {() => {
                                                    let file;
                                                    api.getFileFromDrive(this.state.profileImage).then(res => {
                                                        file = res.data.data.webViewLink
                                                    })
                                                    if(file){
                                                        return <img src={file}/>
                                                    }else{
                                                        <span>new</span>
                                                    }
                                                }} */}
                                                <img alt="///" id='profileimg' src={this.getMedia(this.state.profileImage, 'profileimg')}
                                                // onError={(e) => {
                                                //     e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light';
                                                //     e.target.parentNode.setAttribute("data-name", this.state.name.charAt(0) + this.state.name.split(' ')[this.state.name.split(' ').length - 1].charAt(0));
                                                //     e.target.style.opacity = 0;
                                                // }}
                                                // onLoad={(e) => { e.target.style.opacity = 1 }}
                                                // onError={(e) => {
                                                //     console.log('e ', e);
                                                //     api.getFileFromDrive(this.state.profileImage).then(res => {
                                                //         console.log('this', res.data.data)
                                                //         e.target.src = res.data.data.webContentLink
                                                //     }).catch(er => {
                                                //         e.target.src = ''
                                                //     })
                                                // }}
                                                />
                                            </div>
                                            <button type="button" disabled={!this.state.isProfileEdit} className="btn btn-danger btn-remove" onClick={() => {
                                                this.deleteFile(this.state.profileImage)
                                            }}><i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                        <form>
                                            <div className="form-group">
                                                <label className="form-label">Upload your image</label>
                                                <input type="file" className="form-control" id="file" disabled={!this.state.isProfileEdit} name="file" accept="image/*" onChange={(e) => this.fileUpload(e)} />
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                }
                {apiDialog}
                {loader}
            </React.Fragment>
        )
    }
}

export default Profile;

//https://reactrouter.com/web/example/nesting