import React, { Component } from 'react';
import './privacypolicy.scss';

class Privacypolicy extends Component {
    render() {
        return (
            <div className="page-content privacyPolicy">
                <div class="fluid-banner"><h1>Privacy Policy</h1></div>
                <div className="container inner-content">
                    <p>This Privacy Policy ("Policy") sets forth the collection and usage policies and practices for personally identifiable and other information that apply to the Axl Learn website. By going beyond the website's homepage or participating in the website's services ("Services"), you are deemed to have consented to the policies and practices described in this Policy.
</p>
<h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
<p>Accelerated Learning Technologies (via "www.axllearn.com") may collect and process the following data about you:</p>
<ul>
    <li>Information that you provide by filling in forms on our site, www.axllearn.com, and any related sites includes information provided at the time of registering to use our site, subscribing to our service, posting material or requesting further services. We may also ask you for information when you report a problem with our site.</li>
    <li>When you register for Axl Learn, we ask for your name, email address, phone number and ask you to create a user name and password. Members who sign up are not required to enter credit card information. If you sign up for a Team or Enterprise plan we ask for your credit card information and billing address.</li>
    <li>If you contact us via e-mail, we may keep a record of the email addresses of that correspondence.</li>
    <li>We may ask you to complete surveys that we use for research purposes, although you have no obligation to respond to them.</li>
    <li>We may contact you via e-mail, or phone number provided to update you on our latest offerings, promotions or updates.</li>
    <li>We also collect non-personal aggregate information such as traffic data, location data, weblogs and other communication data for research and development purposes.</li>
</ul>
<h4>HOW WE USE YOUR INFORMATION</h4>
<p>The information we collect is used in the context of providing the Service or to improve the user experience of our web pages and the quality of our Service. Specifically, www.axllearn.com uses collected information for the following general purposes: 
</p>
<ul>
    <li>identification and authentication.</li>
    <li>products and services provision.</li>
    <li>billing.</li>
    <li>contact.</li>
    <li>services improvement.</li>
    <li>and research.</li>
</ul>
<p>The information we collect is not shared with, rented or sold to others except under the following circumstances:</p>
<ul>
    <li>In order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Axl Learn's Terms of Service , or as otherwise required by law.</li>
    <li>To comply with a governmental entity request or with any legal proceeding or process, or if we believe in good faith that such release is necessary in order to protect the rights, property or safety of Axl Learn, our users or other third parties.</li>
    <li>To provide information, products or services you have requested or when we have your permission, or with Axl Learn's business partners or organizations or individuals that Axl Learn employs or engages to perform functions for the site or for the Services, including, but not limited to site hosting entities, web servers or credit card processors.</li>
    <li>If Axl Learn is acquired by or merged with another company. In this event, Axl Learn will notify you via email, in-system alerts and/or with prominent notices on the Axl Learn website before information about you is transferred and becomes subject to a different privacy policy.</li>
</ul>
<h4>DATA STORAGE AND SECURITY</h4>
<p>Axl Learn and/or its licensors own the code, databases, and all rights to the Axl Learn application. You retain all rights to your data. We use third party vendors to provide hosting, necessary applications, hardware, software, financial processing, networking, storage, and related technology.</p>
<p>To protect the security of personal information submitted to us, Axl Learn follows generally accepted industry standards both during transmission and after the information is received. All information you provide to us is stored on secure servers. When you enter sensitive information, i.e. your credit card number, that information is encrypted using secure socket layer technology (SSL).</p>
<p>Where we have given you (or where you have chosen) a password that enables you to access certain parts of our site, you are responsible for keeping this password confidential. If you choose to share your password, i.e. with a virtual assistant, you do so at your own risk. You may change your password at any time by going to your Account page.</p>
<p>No method of transmission over the Internet, or method of electronic storage, is 100% secure. We strive to use commercially acceptable means to protect your personal information. However, we cannot guarantee its absolute security. Axl Learn has no liability for disclosure of your information, whether due to errors, unauthorized and improper acts, or otherwise. If you have further questions about security measures we use at PROASSIST, please email us at <a href="#">support@axllearn.com</a> </p>

<div className='cookiesDisclaimer'>
    <h4>COOKIES</h4>
    <p>Cookies are required to use the Axl Learn Service. If your browser is set to refuse cookies, the Axl Learn application may not work.</p>
    <p>A cookie is a small amount of data, which often includes an anonymous unique identifier. Cookies are sent to your browser from a web site's computers and stored on your computer's hard drive. They help us to improve our site and to deliver a better and more personalized service. They enable us:</p>
            <ul>
                <li>To store information about your preferences, and so allow us to customize our site according to your individual interests and preferences.</li>
                <li>To recognize you when you return to our site.</li>
            </ul>
    <p>We use cookies to record current session information, but do not use permanent cookies.</p>
    <h4>DISCLOSURE</h4>
    <p>Axl Learn may disclose personally identifiable information under special circumstances, such as to comply with court orders or when your actions violate the Terms of Service. Please be advised that if you associate your name or other personally identifiable information with the user name you create for an account, your activities on the site may be identifiable to third parties.</p>
    <h4>CONTENT CROWD SOURCING</h4>
    <p>Axl Learn allows select Authors to create courses, and the same is reviewed for quality and fit for purpose before publishing. When a user or Admin composes a new course, the same will be published within Axl Learn’s library for other companies within the industry publishing the name of the course author. Our aim is to make learning content available for all to benefit from via crowd sourcing.</p>
    <h4>CHANGES TO THIS POLICY</h4>
    <p>Axl Learn reserves the right to modify or update this Policy at any time. If we make significant changes to this Policy, we will notify you via notifications, prominently displayed on Axl Learn’s website and/or via email.</p>
    <h4>USER AGE</h4>
    <p>The Site and the Services is created for children, youth, and adults for learning purpose only.</p>
    <h4>QUESTIONS</h4>
    <p>Any questions about this Privacy Policy should be emailed to <a href="#">support@axllearn.com</a></p>
    <h4>DISCLAIMER</h4>
    <p>Axl Learn collects information and data which are classified under different categories for the purpose of data sorting and analysis. The classification criteria is solely on a scientific basis for the purpose of data and subject research and not for profiling under any circumstances. There is no scope for generalization of classification criteria other than through scientific analysis. Axl Learn does not support, promote nor indulge in any form of discrimination on the basis of gender, location, region, or age group. Axl Learn, likewise, will not be responsible for any allegation of profiling or discrimination on any basis by anyone as no one is authorized to use the database for any purposes other than those listed in the package. Axl Learn is also not responsible for any unauthorized misuse of the data stored in the database by any person. Axl Learn takes all precautions to prevent unauthorized persons from copying or hacking into the database by securing the servers where the data is deposited.</p>
</div>
                </div>
            </div>
        )
    }
}

export default Privacypolicy;