
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import Api from '../../../services/Api/Api';
import { validateEmail, validTextField } from '../../../utils/constant';
import Loader from '../../shared/loader/loader';
import { Modal } from '../../shared/modal/modal';
import "./actionplan.scss";
import Preview from './preview';

const ActionPlan = () => {

    const initialActionPlanData =
    {
        title: "",
        keyLearning: "",
        actionPlan: "",
        requiredResource: "",
        successCriteria: "",
        recipientMail: "",
    }


    const [actionPlanData, setActionPlanData] = useState(initialActionPlanData)

    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState({
        resMessage: "",
        resType: "",
    })

    const [displayPreview, setDisplayPreview] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const courseName = location.state?.courseName;
    const userName = location.state?.userName;
    const totalDuration = location.state?.totalDuration;
    const courseId = location.state?.courseId;
    const endDate = location.state?.endDate;

    console.log(courseName, userName, totalDuration)

    function handleChange({ target: { name, value } }) {
        setActionPlanData({ ...actionPlanData, [name]: value })
    }


    function isEnableButton() {
        const keys = Object.keys(actionPlanData);
        for (let i = 0; i < keys.length; i++) {
            if(i===keys.length-1 && !validateEmail(actionPlanData[keys[i]]))
            return true
            else{
            if (!validTextField(actionPlanData[keys[i]]))
                return true;
            }
        }
        return false;
    }
    async function onSubmit() {

        try {
            setIsLoading(true);
            const { data: { resMessage, resType } } = await Api.actionPlan(actionPlanData);

            setResponseData({
                resMessage: resMessage,
                resType: resType

            })

        } catch (error) {
            console.log(error)
            setResponseData({
                resMessage: "Something went wrong! Please try again",
                resType: 'danger'
            })
        }
        finally {
            setIsLoading(false)
            resetActionPlanData();
        }
    }


    const apiDialog = () => {
        return (
            <>
                {responseData.resMessage !== '' && <Modal> <div className={`text-center alert alert-${responseData.resType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
                    <p>{responseData.resMessage}</p>
                    <br />
                    <button type="button" onClick={resetResponseData} className={`btn btn-${responseData.resType}`}>Okay</button>
                </div>
                </Modal>}
            </>
        )
    }

    const loader = () => {
        return (
            isLoading && <Modal>
                <Loader message={"Loading..."} />
            </Modal>

        )
    }

    function resetActionPlanData() {
        setActionPlanData(initialActionPlanData)
    }
    function resetResponseData() {
        if(responseData.resType !=='danger'){
        history.push({
            pathname: `/certificate/${userName}-${courseId}`, state: {
                courseName: courseName,
                userName: userName,
                totalDuration: totalDuration,
                endDate:endDate
            }
        })
    }

    setResponseData({
        resMessage: '',
        resType: ''
    })

    }
 
    function gotoCertificatePage(){
        history.push({
            pathname: `/certificate/${userName}-${courseId}`, state: {
                courseName: courseName,
                userName: userName,
                totalDuration: totalDuration,
                endDate:endDate
            }
        })
    }

    return (
        <React.Fragment>

            {loader()}
            {apiDialog()}
            {displayPreview && <Preview setDisplayPreview={setDisplayPreview} actionPlanData={actionPlanData} />}
            <div className="page-content">
                <div className='container'>
                    <div className='action-plan'>
                        <div className="back-button">
                        <button type='button' onClick={()=>history.goBack()} className='btn btn-primary'>Back</button>
                        </div>
                        <div className='ap-header'>
                            <h4>Add your action plan</h4>
                            <button type='button' className='btn btn-primary'>{courseName}</button>
                        </div>
                        <div className='aplan-block'>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Title</label>
                                        <p className='small-text'>Give your action plan a title.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <input type="text" className="form-control" id="pname" placeholder="Plan name" name="title" value={actionPlanData.title} onChange={handleChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Key Learning</label>
                                        <p className='small-text'>Identify and list your key learnings from the course.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <textarea className="form-control" id="objectives" rows="3" name="keyLearning" value={actionPlanData.keyLearning} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Action Plans</label>
                                        <p className='small-text'>Ex : Prepare a Stakeholder Register for my existing project.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <textarea className="form-control" id="objectives" rows="3" name="actionPlan" value={actionPlanData.actionPlan} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Required Resources</label>
                                        <p className='small-text'>List the Physical, People, or Leadership support you require.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <textarea className="form-control" id="objectives" rows="3" name="requiredResource" value={actionPlanData.requiredResource} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Success Criteria</label>
                                        <p className='small-text'>How do you measure success? Ex : Stakeholder Register is agreed by my  Project Supervisor.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <textarea className="form-control" id="objectives" rows="3" name="successCriteria" value={actionPlanData.successCriteria} onChange={handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <label className="required">Recipient Email</label>
                                        <p className='small-text'>Send this plan to your Manager.</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <input type="text" className="form-control" id="pname" placeholder="company.manager@comp.com" name="recipientMail" value={actionPlanData.recipientMail} onChange={handleChange}></input>
                                    </div>
                                </div>
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='btn-block'>
                                            <button type='button' className='btn btn-success btn-cert' onClick={gotoCertificatePage}>Certificate <i class="bi bi-arrow-right-short"></i></button>
                                        </div>
                                    </div>
                                    <div className='col-sm-8'>
                                        <div className='btn-block text-right'>
                                            <button type='button' onClick={() => setDisplayPreview(true)} disabled={isEnableButton()} className='btn btn-dark' ><i className="bi bi-eye"></i> Preview</button>
                                            <button type='button' onClick={onSubmit} disabled={isEnableButton()} className='btn btn-primary btn-send'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                </svg> Send</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}

export default ActionPlan;

