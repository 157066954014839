import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../services/Api/Api';

class Quiz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chapterSubmitPage: null,
            score: 0,
            quizAnswerIdx:this.props.quizAnswerIdx,
            max_questions: 0,
            currentQuizIndex: 0,
            submitPage:false,
        }
        this.retakeQuiz = this.retakeQuiz.bind(this)
    }
  

    componentDidMount(){
     if(this.props.isQuizCompleted){
         this.setState({
             submitPage:true,
             score:this.props.quizScore,
             quizAnswerIdx:this.props.quizAnswerIdx
         })
     }
    }
    componentDidUpdate(prevProps,prevState) {
        if(prevProps.contentName!==this.props.contentName){
            const score=this.props.isQuizCompleted?this.props.quizScore:0;
            const submitPage=this.props.isQuizCompleted?true:false;
            this.setState({
                submitPage:submitPage,
                score:score,
                quizAnswerIdx:this.props.quizAnswerIdx
            })
        }
      }

    onNextQuestions = () => {
        if (this.state.currentQuizIndex < this.props.quiz_questions.length - 1) {

            this.setState({
                currentQuizIndex: this.state.currentQuizIndex + 1,
                checkedAns: true
            })
        }
    }
    onBackQuestions = () => {
        if (this.state.currentQuizIndex > 0) {

            this.setState({
                currentQuizIndex: this.state.currentQuizIndex - 1

            })
        }
    }
    getCourseUpdateDetails(quizScore,quizAnswerIdx){
        let update_learner = JSON.parse(JSON.stringify(this.props.learnerCourses));
        // ------------- callback hell -------------
        update_learner.forEach((elem, ei) => {
            if (elem.id === this.props.courseID) {
                elem.status.forEach((s_elem, si) => {
                    if (s_elem.chapterId === this.props.topicID) {
                        s_elem.progress.forEach((pr_elem, pi) => {
                            if (pr_elem.name === this.props.contentName) {
                               pr_elem.score=quizScore;
                               pr_elem.quizAnswerIdx=quizAnswerIdx;
                               pr_elem.completed=100;
                            };   
                        });
                    };
                });
            };
        });

        return update_learner;
     }
      currentDate (){
        const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
      
      const formattedToday = dd + '.' + mm + '.' + yyyy;
      
      return formattedToday;
      }
    onSubmitQuestions = () => {
        let score = 0;
        this.props.quiz_questions.forEach((question,quesIdx) => {
            const correctIndexes = [];
            question.options.forEach((op, i) => {
                if (op.status) {
                    correctIndexes.push(i);
                }
            });
            if (correctIndexes.length === this.state.quizAnswerIdx[quesIdx].length && this.state.quizAnswerIdx[quesIdx].every(it => correctIndexes.includes(it))) {
                score++;
            }   
        })
        if (this.props.isFinalAssessment) {
            const data={
                courseId:this.props.courseId,
                score:score,
                endDate:this.currentDate(),
                finalAssesmentAnswerIdx: this.state.quizAnswerIdx
            }
            Api.finalAssessmentUpdate(data).then((response) => {
                if (response.data.resCode === 200) 
                {   
                this.setState({submitPage:true,score:score},()=>{
                    this.props.reloadCourseDetails();
                })
                }
            }).catch((error) => {
                console.log(error)
            });
        }
        else{
          const updateCourseDetails=this.getCourseUpdateDetails(score,this.state.quizAnswerIdx) 
          Api.updateCourses({ courses: updateCourseDetails }).then((response) => {
            if (response.data.resCode === 200) 
            {   
            this.setState({submitPage:true,score:score},()=>{
                this.props.reloadCourseDetails();
            })
            }
        }).catch((error) => {
            console.log("course status update error: ", error);
        });
        }
    }
    
    retakeQuiz(){
        this.setState({
            submitPage:false,
            score:0
        })
    }
    render() {
        console.log("HELLO",this.props.quiz_questions,this.state.quizAnswerIdx)
        return (
            <div className="quiz-container">
                {
                    this.state.submitPage ?

                        <div className="quiz-container-inner">
                            <div className="score-retake-button-section">
                                <div className="score-section">
                                <h4 className='score-number'><i className="bi bi-check-circle-fill"></i> Your score is: <span className='score'>{this.state.score}</span></h4>
                                </div>
                                {!this.props.isFinalAssessment && (
                                <div className="retake-button-section">
                                    <button className="btn btn-highlight-secondary" onClick={this.retakeQuiz}>Retake Quiz</button>       
                                </div>

                            )}
                            </div>
                            
                         
                            <div className='quiz-anspage'><h3>Your Result</h3>
                                {
                                    this.props.quiz_questions.map((q, i) => {
                                        console.log(q)
                                        return (
                                            <div key={uuidv4()} className="question-list">
                                                <div className='correctAns'>
                                                    <h4>Q{i + 1}.{`  ${q.question}`}</h4>
                                                    <h5><span className='correctAnstext'>Your Answers:</span> <span className='ifyes'>{this.state.quizAnswerIdx[i].map(op => q.options[op].text).join(',')}</span></h5>
                                                </div>
                                                <div className='correctAns'>
                                                    <h5><span className='correctAnstext'>Correct Answers:</span> <span className='ifyes'>{q.options.filter(op => op.status).map(op => op.text).join(',')}</span></h5>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            
                        </div>
                        :

                        // this.state.quizPageOpen &&
                        <div className="question-block">
                            <p><span className="q-number">Q{this.state.currentQuizIndex + 1}. </span> {this.props.quiz_questions[this.state.currentQuizIndex].question}</p>
                            {
                                this.props.quiz_questions[this.state.currentQuizIndex].options.map((optionsElement, optionsIndex) => {
                                    return (
                                        <div className="form-check" key={optionsIndex}>
                                            <input className="form-check-input" type="checkbox" checked={Object.keys(this.state.quizAnswerIdx).length>0 && this.state.quizAnswerIdx[this.state.currentQuizIndex].includes(optionsIndex)}
                                                onChange={(e) => {
                                                    if (Object.keys(this.state.quizAnswerIdx).length>0 && this.state.quizAnswerIdx[this.state.currentQuizIndex].includes(optionsIndex)) {
                                                        const findIndex = this.state.quizAnswerIdx[this.state.currentQuizIndex].indexOf(optionsIndex);
                                                        let obj={...this.state.quizAnswerIdx}
                                                        obj[this.state.currentQuizIndex].splice(findIndex, 1);
                                                        this.setState(({quizAnswerIdx:obj}))
                                                    } else {
                                                        let obj={...this.state.quizAnswerIdx}
                                                        obj[this.state.currentQuizIndex].push(optionsIndex)
                                                        this.setState(({quizAnswerIdx:obj}))
                                     
                                                    }
                                                    // this.forceUpdate();
                                                    
                                                 
                                                }}></input>

                                            <label className="form-check-label" htmlFor="flexRadioDefault1">{optionsElement.text}</label>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.currentQuizIndex > 0 &&
                                <button type="button" className="btn btn-highlight-secondary back-button"
                                    onClick={this.onBackQuestions}   ><i className="bi bi-arrow-left"></i> Back</button>

                            }

                            {/* <button onClick={()=>console.log(this.props.quiz_questions)}>quiz props</button> */}
                            <input
                                type="range"
                                min={0} max={this.props.quiz_questions.length - 1}
                                value={this.state.currentQuizIndex}

                                onChange={(e) => {
                                   

                                    if (Number(e.target.value) < this.state.currentQuizIndex) {
                                        this.setState({
                                            // max_questions:  this.state.currentQuizIndex ,
                                            currentQuizIndex: Number(e.target.value)
                                        })
                                    }

                                    if (this.props.quiz_questions[this.state.currentQuizIndex].answers.length !== 0) {
                                        console.log("input =", this.props.quiz_questions[Number(e.target.value)].answers)
                                        this.setState(prevState => ({
                                            currentQuizIndex: Number(e.target.value),

                                            // max_questions: Number(e.target.value) > this.state.currentQuizIndex && Number(e.target.value)

                                        }))
                                    }
                                }}
                                onClick={(e) => {
                                    // console.log("click =", e.target.value)
                                }}
                                step={1}
                            />
                            {
                                this.state.currentQuizIndex < this.props.quiz_questions.length - 1 && this.state.quizAnswerIdx[this.state.currentQuizIndex].length !== 0 &&
                                <button type="button" className="btn btn-highlight-success next-button"
                                    onClick={this.onNextQuestions}   >Next Question <i className="bi bi-chevron-right"></i></button>

                            }


                            {
                                this.state.currentQuizIndex === this.props.quiz_questions.length - 1 && this.state.quizAnswerIdx[this.state.currentQuizIndex].length !== 0 &&
                                <button type="button" className="btn btn-highlight-success next-button"
                                    onClick={this.onSubmitQuestions} > Submit </button>

                            }
                            {/* <h1>Percentage of quiz complete : {Math.floor((this.state.currentQuizIndex / (this.props.quiz_questions.length - 1)) * 100)}% </h1> */}
                        </div>


                }
            </div>

        )
    }
}

export default Quiz