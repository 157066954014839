import React, { Component } from 'react';
import './authorterms.scss';
import { Link } from "react-router-dom";

class AuthorTerms extends Component {
  render() {
    return (
      <div className="page-content">
        <div class="fluid-banner"><h1>Author Terms and Conditions</h1></div>
        <div className="container inner-content">
          <div className='termsofuse'>
            <h4>Course Author Terms</h4>
            <h6>These Course Author Terms were last updated May 3, 2021.</h6>
            <p>When you sign up to become a Course Author on the Axl Learn platform, you agree to abide by these Course Author Terms <strong>("Terms")</strong>. These Terms cover details about the aspects of the Axl Learn platform relevant to Course Authors and are incorporated by reference into our Terms of Use, the general terms that govern your use of our Services. Any capitalized terms that aren't defined in these Terms are defined as specified in the Terms of Use.</p>
            <p>As an Course Author, you are contracting directly with Axl Learn (in India), regardless of whether another Axl Learn subsidiary facilitates payments to you.</p>
            

            <div className='terms-points'>
              <h4>1. Course Author Obligations</h4>
              <p>As an Course Author, you are responsible for all content that you post, including lectures, quizzes, coding exercises, practice tests, assignments, simulations, links, documents, resources, answers, course landing page content, labs, assessments, and announcements<strong>("Submitted Content").</strong></p>
              <p>You represent and warrant that:</p>
              <ul>
                <li>You will provide and maintain accurate account information;</li>
                <li>You own or have the necessary licenses, rights, consents, permissions, and authority to authorize Axl Learn to use your Submitted Content as specified in these Terms and the Terms of Use;</li>
                <li>Your Submitted Content will not infringe or misappropriate any third party's intellectual property rights;</li>
                <li>You have the required qualifications, credentials, and expertise (including education, training, knowledge, and skill sets) to teach and offer the services that you offer through your Submitted Content and use of the Services; and</li>
                <li>You will ensure a quality of service that corresponds with the standards of your industry and instruction services in general.</li>
              </ul>
              <p>You warrant that you will not:</p>
              <ul>
                <li>Post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, infringing, defamatory or libelous content or information;</li>
                <li>Post or transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, or any other form of solicitation (commercial or otherwise) through the Services or to any user;</li>
                <li>Use the Services for business other than providing tutoring, teaching, and instructional services to Course Learners;</li>
                <li>Engage in any activity that would require us to obtain licenses from or pay royalties to any third party, including the need to pay royalties for the public performance of a musical work or sound recording;</li>
                <li>Frame or embed the Services (such as to embed a free version of a course) or otherwise circumvent the Services;</li>
                <li>Impersonate another person or gain unauthorized access to another person's account;</li>
                <li>Host the same course you published in Axl Learn platform in social media and free media sites like YouTube, etc. </li>
                <li>Interfere with or otherwise prevent other Course Authors from providing their services or content; or</li>
                <li>Abuse Axl Learn resources, including support services.</li>
              </ul>
            </div>
            <div className='terms-points'>
              <h4>2. License to Axl Learn</h4>
              <p>You grant Axl Learn the rights detailed in the Terms of Use to offer, market, and otherwise exploit your Submitted Content. This includes the right to add captions or otherwise modify Submitted Content to ensure accessibility. You also authorize Axl Learn to sublicense these rights to your Submitted Content to third parties, including to Course Learners directly and through third parties such as resellers, distributors, affiliate sites, deal sites, and paid advertising on third-party platforms.</p>
              <p>Unless otherwise agreed (including within our Promotions Policy), you have the right to remove all or any portion of your Submitted Content from the Services at any time. Except as otherwise agreed, Axl Learn's right to sublicense the rights in this section will terminate with respect to new users 60 days after the Submitted Content's removal. However, (1) rights given to Course Learners before the Submitted Content's removal will continue in accordance with the terms of those licenses (including any grants of lifetime access) and (2) Axl Learn's right to use such Submitted Content for marketing purposes shall survive termination.</p>
              <p>We may record and use all or any part of your Submitted Content for quality control and for delivering, marketing, promoting, demonstrating, or operating the Services. You grant Axl Learn permission to use your name, likeness, voice, and image in connection with offering, delivering, marketing, promoting, demonstrating, and selling the Services, your Submitted Content, or Axl Learn's content, and you waive any rights of privacy, publicity, or other rights of a similar nature, to the extent permissible under applicable law.</p>
            </div>
            <div className='terms-points'>
              <h4>3. Trust & Safety</h4>
              <div className='terms-subpoints'>
                <h5>3.1 Trust & Safety Policies</h5>
                <p>You agree to abide by Axl Learn's Trust & Safety policies, Restricted Topics policy, and other content quality standards or policies prescribed by Axl Learn from time to time. You should check these policies periodically to ensure that you comply with any updates to them. You understand that your use of the Services is subject to Axl Learn's approval, which we may grant or deny at our sole discretion.</p>
                <p>We reserve the right to remove content, suspend payouts, and/or ban Course Authors for any reason at any time, without prior notice, including in cases where:</p>
                <ul>
                  <li>An Course Author or content does not comply with our policies or legal terms (including the Terms of Use);</li>
                  <li>Content falls below our quality standards or has a negative impact on the Course Learner experience;</li>
                  <li>An Course Author engages in behavior that might reflect unfavorably on Axl Learn or bring Axl Learn into public disrepute, contempt, scandal, or ridicule;</li>
                  <li>An Course Author engages the services of a marketer or other business partner who violates Axl Learn's policies;</li>
                  <li>An Course Author uses the Services in a way that constitutes unfair competition, such as promotion of their off-site business in a way that violates Axl Learn's policies; or</li>
                  <li>As determined by Axl Learn in its sole discretion.</li>
                </ul>
              </div>
              <div className='terms-subpoints'>
                <h5>3.2 Relationship to Other Users</h5>
                <p>Course Authors don't have a direct contractual relationship with Course Learners, so the only information you'll receive about Course Learners is what is provided to you through the Services. You agree that you will not use the data you receive for any purpose other than providing your services to those Course Learners on the Axl Learn platform, and that you won't solicit additional personal data or store Course Learners' personal data outside the Axl Learn platform. You agree to indemnify Axl Learn against any claims arising from your use of Course Learners' personal data.</p>
              </div>
              <div className='terms-subpoints'>
                <h5>3.3 Anti-Piracy Efforts</h5>
                <p>We partner with anti-piracy vendors to help protect your content from unauthorized use. To enable this protection, you hereby appoint Axl Learn and our anti-piracy vendors as your agents for the purpose of enforcing copyrights for each of your content, through notice and takedown processes (under applicable copyright laws like the Digital Millennium Copyright Act) and for other efforts to enforce those rights. You grant Axl Learn and our anti-piracy vendors primary authority to file notices on your behalf to enforce your copyright interests.</p>
                <p>You agree that Axl Learn and our anti-piracy vendors will retain the above rights unless you revoke them by sending an email to piracy@Axl Learn.com with the subject line: "Revoke Anti-Piracy Protection Rights" from the email address associated with your account. Any revocation of rights will be effective 48 hours after we receive it.</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>4. Pricing</h4>
              <div className='terms-subpoints'>
                <h5>4.1 Price Setting</h5>
                <p>When creating Submitted Content available for purchase on Axl Learn, you will be prompted to select a base price ("Base Price") for your Submitted Content from a list of available price tiers. Alternatively, you may choose to offer your Submitted Content for free. As a premium Course Author, you will also be given the opportunity to participate in certain promotional programs under the terms of our Promotions Policy ("Promotional Programs").</p>
                <p>If you do not opt to participate in any Promotional Programs, we will list your Submitted Content for the Base Price or the closest local or mobile app equivalent (as detailed below). If you opt to participate in a Promotional Program, we may set a different discounted price or list price for your Submitted Content as described in the Promotions Policy.</p>
                <p>When a Course Learner purchases using a foreign currency, we will convert the relevant Base Price or Promotional Program price into the Course Learner's applicable currency using a system-wide foreign currency conversion rate set by Axl Learn and fixed periodically into a table of corresponding price tiers by currency ("Price Tier Matrix"). Since the Price Tier Matrix is fixed, those conversion rates may not be identical to the applicable market rate in effect when a transaction is processed. We reserve the right to update the Price Tier Matrix at any time. The Price Tier Matrix and additional information on Axl Learn's pricing tiers are available here.</p>
                <p>When a Course Learner purchases through a mobile application, the mobile platform provider's pricing matrix will control, and we will choose the price tier closest to the applicable Base Price or Promotional Program price. Because mobile platforms impose their own currency conversion rates, conversions for mobile app prices may not match the conversions in the Price Tier Matrix.</p>
                <p>You give us permission to share your Submitted Content for free with our employees, with selected partners, and in cases where we need to restore access to accounts who have previously purchased your Submitted Content. You understand that you will not receive compensation in these cases.</p>
              </div>
              <div className='terms-subpoints'>
                <h5>4.2 Transaction Taxes</h5>
                <p>If a Course Learner purchases a product or service in a country that requires Axl Learn to remit national, state, or local sales or use taxes, value added taxes (VAT), or other similar transaction taxes ("Transaction Taxes"), under applicable law, we will collect and remit those Transaction Taxes to the competent tax authorities for those sales. We may increase the sale price at our discretion where we determine that such taxes may be due. For purchases through mobile applications, applicable Transaction Taxes are collected by the mobile platform (such as Apple's App Store or Google Play).</p>
              </div>
              <div className='terms-subpoints'>
                <h5>4.3 Promotional Programs</h5>
                <p>Axl Learn offers several optional marketing programs (Promotional Programs) in which you can choose to participate, as detailed in our Promotions Policy. These programs can help increase your revenue potential on Axl Learn by finding the optimal price point for your Submitted Content and offering them through subscriptions collections.</p>
                <p>There is no up-front cost to participate in these programs, and you can modify your participation status at any time, though changes you make will not apply to currently active campaigns and certain programs may have additional requirements on termination.</p>
              </div>
            </div>
            <div className='terms-points payHighlited'>
              <h4>5. Payments</h4>
                <div className='terms-subpoints'>
                  <h5>5.1 Revenue Share</h5>
                  <p>When a Course Learner purchases your Submitted Content, we calculate the gross amount of the sale as the amount actually received by Axl Learn from the Course Learner ("Gross Amount"). From this, we subtract any Transaction Taxes, any mobile platform fees applied to mobile provider checkout sales, a 3% service and processing fee (except in Japan, where we subtract a 4% fee) for any non-mobile provider checkout sales, and any amounts paid to third parties in connection with the Promotional Programs to calculate the net amount of the sale ("Net Amount").</p>
                  <p>If you have not opted into any of Axl Learn's optional Promotional Programs, and except for sales through Course Author-generated coupon codes or course referral links as described below, your revenue share will be 37% of the Net Amount less any applicable deductions, such as Course Learner refunds. If we change this payment rate, we will provide you 30 days notice using prominent means, such as via email or by posting a notice through our Services.</p>
                  <p>If you opt into any of the Promotional Programs, the relevant revenue share may be different and will be as specified in the Promotions Policy.</p>
                  <p>Axl Learn makes all Course Author payments in U.S. dollars (USD) regardless of the currency with which the sale was made. Axl Learn is not responsible for your foreign currency conversion fees, wiring fees, or any other processing fees that you may incur. Your revenue report will show the sales price (in local currency) and your converted revenue amount (in USD).</p>
                </div>
                <div className='terms-subpoints'>
                  <h5>5.2 Course Author Coupons and Course Referral Links</h5>
                  <p>The Axl Learn platform allows you to generate coupon codes and referral links to offer certain items of your Submitted Content to Course Learners at a discount, at Axl Learn's current price, or for free, as permitted within the Services. These coupon codes and referral links are subject to limits, and you may not sell them on third-party websites or otherwise offer them in exchange for compensation. Additional information and restrictions on these coupon codes and referral links are outlined in our Trust & Safety policies.</p>
                  <p>Where a Course Learner applies your coupon code or referral link at checkout, your revenue share will be 97% of the Net Amount less any applicable deductions, such as Course Learner refunds.</p>
                </div>
                <div className='terms-subpoints'>
                  <h5>5.3 Receiving Payments</h5>
                  <p>For us to pay you in a timely manner, you must own a PayPal, Payoneer, or U.S. bank account (for U.S. residents only) in good standing and must keep us informed of the correct email associated with your account. You must also provide any identifying information or tax documentation (such as a W-9 or W-8) necessary for payment of amounts due, and you agree that we have the right to withhold appropriate taxes from your payments. We reserve the right to withhold payments or impose other penalties if we do not receive proper identifying information or tax documentation from you. You understand and agree that you are ultimately responsible for any taxes on your income.</p>
                  <p>Depending on the applicable revenue share model, payment will be made within 45 days of the end of the month in which (a) we receive the fee for a course or (b) the relevant course consumption occurred.</p>
                  <p>As an Course Author, you are responsible for determining whether you are eligible to be paid by a U.S. company. We reserve the right not to pay out funds in the event of identified fraud, violations of intellectual property rights, or other violations of the law.</p>
                  <p>If we cannot settle funds into your payment account after the period of time set forth by your state, country, or other government authority in its unclaimed property laws, we may process the funds due to you in accordance with our legal obligations, including by submitting those funds to the appropriate government authority as required by law.</p>
                </div>
                <div className='terms-subpoints'>
                  <h5>5.4 Refunds</h5>
                  <p>You acknowledge and agree that Course Learners have the right to receive a refund, as detailed in the Terms of Use. Course Authors will not receive any revenue from transactions for which a refund has been granted under the Terms of Use.</p>
                  <p>If a Course Learner asks for a refund after we have paid the relevant Course Author payment, we reserve the right to either (1) deduct the amount of the refund from the next payment sent to the Course Author or (2) where no further payments are due to the Course Author or the payments are insufficient to cover the refunded amounts, require the Course Author to refund any amounts refunded to Course Learners for the Course Author's Submitted Content.</p>
                </div>
            </div>
            <div className='terms-points'>
                <div className='terms-subpoints'>
                  <h4>6. Trademarks</h4>
                  <p>While you are a published Course Author and subject to the requirements below, you may use our trademarks where we authorize you to do so.</p>
                  <p>You must:</p>
                  <ul>
                    <li>Only use the images of our trademarks that we make available to you, as detailed in any guidelines we may publish;</li>
                    <li>Only use our trademarks in connection with the promotion and sale of your Submitted Content available on Axl Learn or your participation on Axl Learn; and</li>
                    <li>Immediately comply if we request that you discontinue use.</li>
                  </ul>
                  <p>You must not:</p>
                  <ul>
                    <li>Use our trademarks in a misleading or disparaging way;</li>
                    <li>Use our trademarks in a way that implies that we endorse, sponsor, or approve of your Submitted Content or services; or</li>
                    <li>Use our trademarks in a way that violates applicable law or in connection with an obscene, indecent, or unlawful topic or material.</li>
                  </ul>
                </div>
            </div>
            <div className='terms-points deleteAccount'>
              <h4>7. Deleting Your Account</h4>
              <div className='terms-subpoints'>
               <p>Instructions on how to delete your Course Author account are available here. We'll use commercially reasonable efforts to make any remaining scheduled payments that are owed to you before deleting your account. You understand that if Course Learners have previously enrolled in your Submitted Content, your name and that Submitted Content may remain accessible to those Course Learners after your account is deleted. If you need help or encounter difficulty deleting your account, you can contact us via our Support Center.</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>8. Miscellaneous Legal Terms</h4>
              <div className='terms-subpoints'>
                <h5>8.1 Updating These Terms</h5>
                <p>From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and Axl Learn reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise.</p>
                <p>Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.</p>
                <h5>8.2 Translations</h5>
                <p>Any version of these Terms in a language other than English is provided for convenience and you understand and agree that the English language will control if there is any conflict.</p>
                <h5>8.3 Relationship Between Us</h5>
                <p>You and we agree that no joint venture, partnership, employment, contractor, or agency relationship exists between us.</p>
                <h5>8.4 Survival</h5>
                <p>The following sections shall survive the expiration or termination of these Terms: Sections 2 (License to Axl Learn), 3.3 (Relationship to Other Users), 5.3 (Receiving Payments), 5.4 (Refunds), 7 (Deleting Your Account), and 8 (Miscellaneous Legal Terms).</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>The best way to get in touch with us is to contact our <Link target="_blank" className='text-primary' to="/helpsupport">Support Team</Link>. We'd love to hear your questions, concerns, and feedback about our Services.</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AuthorTerms;