import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Rating from '@material-ui/lab/Rating';
import api from '../../../../services/Api/Api';
import VimeoPlayer from '../../../shared/vimeoplayer/vimeoplayer';

const ListingModule = (props) => {

    const [courses, set_courses] = useState([]);
    const [resMessage, set_resMessage] = useState("")

    useEffect(() => {
        let reqQuery = {
            query: { status: 'published', category: props.category },
            limit: 10,
            skip: 0
        }
        api.courseSearch(reqQuery).then(res => {
            if (res.data.resCode === 200) {
                set_courses(res.data.data.courses);
            } else {
                set_resMessage(res.data.resMessage);
            }
        }).catch((err) => {
            set_resMessage("Something wrong is there, please reload after sometime");
        })
    }, [])
    return (
        <React.Fragment>
            <div className="course-container">
                <h1> {props.heading} <span className="catg-text">{props.category}</span></h1>
                <div className="course-list">
                    {courses.length > 0 &&
                        <ul>
                            {courses.map((item, i) => {
                                return <li key={uuidv4()}>
                                    <Card className="product-card">
                                        <CardActionArea>
                                            <div className="product-img">
                                                {item.introVideo && item.introVideo !== '' &&
                                                    <VimeoPlayer
                                                        id={item.introVideo}
                                                        preId={'axllearn'}
                                                        options={{
                                                            height: 200,
                                                            byline: false, // vimeo owner's name
                                                            color: 'ff0000',
                                                            responsive: false,
                                                            quality: 'auto',
                                                            portrait: false,
                                                            playsinline: true,
                                                            transparent: false,
                                                            muted: true,
                                                            controls: false
                                                        }}
                                                        isPlayOnOver={true}
                                                    />
                                                }
                                                {(item.introVideo === '' || !item.introVideo) &&
                                                    <img id={item.poster + 'axllearn'} src={item.poster} alt="///"
                                                        onError={(e) => {
                                                            e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light w-100 placeholder';
                                                            e.target.parentNode.innerText = item.title;
                                                            e.target.style.display = 'none';
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <CardContent>
                                                <h4><NavLink className="product-title" to={`/courseDetails/${item._id}`}>{item.title}</NavLink></h4>
                                                <h6>
                                                    <span>{item?.author?.profile?.firstName && item?.author?.profile?.lastName ? <>{item.author.profile.firstName}&nbsp;{item.author.profile.lastName}</> : <>From Axllearn</>}</span>
                                                    {/* <span>Views - 3,213</span> */}
                                                </h6>
                                                <h6>
                                                    <span>{item.ratings.length > 0 ? (item.ratings.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0) / item.ratings.length).toFixed(1).replace(/[.,]0$/, "") : 0}
                                                        <Rating name="size-small"
                                                            defaultValue={
                                                                item.ratings.length > 0 ? (item.ratings.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0) / item.ratings.length).toFixed(1).replace(/[.,]0$/, "") : 0
                                                            } size="small" />
                                                    </span>
                                                    <span className="raters">{item.ratings.length}</span>
                                                </h6>
                                                <div className='priceOsim'>
                                                    {/* <h5 className="course-price">₹3,449</h5>
                                                    <p><i class="bi bi-patch-check"></i> Simulator</p> */}
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </li>
                            })}
                        </ul>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ListingModule);


