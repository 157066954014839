import React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

const portal = document.getElementById('modal');

export class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.element = document.createElement('div');
        this.element.setAttribute('class', 'modal-elem');
    }

    componentDidMount = () => {
        portal.appendChild(this.element);
    }

    componentWillUnmount = () => {
        portal.removeChild(this.element);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.element
        )
    }

}

// export default Modal;

export class ApiModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const props = this.props;
        return (
            <React.Fragment>
                <div className="modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className={`modal-content alert-success`}>
                            <div className="modal-header border-0">
                                <h5 className={`modal-title text-${props.modalData.resType}`}><span className="text-capitalize">{props.modalData.resTitle}</span></h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}></button>
                            </div>
                            <div className="modal-body border-0">
                                <p className="text-success">{props.modalData.resMessage}</p>
                            </div>
                            <div className="modal-footer border-0">
                                {props.reload &&
                                    <button type="button" className={`btn btn-gray`} onClick={() => props.reload()}><i className="bi bi-arrow-clockwise"></i> Reload</button>
                                }
                                <button type="button" className={`btn btn-${props.modalData.resType} ${props.modalData.resType !== 'light' ? 'text-light' : 'text-dark'}`} onClick={() => props.dismiss()}>Okay</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export class SignUpModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            answers: [],
            category: ''
        }
    }
    checkAnswer(item) {
        let answers = [...this.state.answers];
        let ans_index = answers.map(itm => { return itm }).indexOf(item);
        if (ans_index > -1) {

            return true;
        } else {
            return false;
        }
    }
    render() {
        const props = this.props;
        return (
            <React.Fragment>
                <div className="modal modal-signup">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                
                        <div className="modal-content account-setup">
                            <div className="modal-header">
                                <div className="modal-title">
                                    <h5><span className="text-capitalize">Setup your Account</span></h5>
                                    <h6>Questions are asked to confirm the identity of the Course Learner.</h6>
                                </div>
                                <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}></button>
                            </div>
                            {/* Account Setup Step 1 Start */}
                            {!props.completed &&
                                <div className="modal-body">
                                    <h4>{props.modalData.question.q}</h4>
                                    <ul>
                                        {props.modalData.question.a.map((item, i) => {
                                            return <li key={uuidv4()}
                                                onClick={() => {
                                                    let answers = [...this.state.answers];
                                                    let answer_index = answers.map(itm => { return itm }).indexOf(item);
                                                    if (answer_index > -1) {
                                                        answers.splice(answer_index, 1);
                                                    } else {
                                                        answers.push(item);
                                                    };
                                                    this.setState({
                                                        question: props.modalData.question.q,
                                                        category: props.modalData.question.c,
                                                        answers: answers
                                                    })
                                                }}>
                                                <div className="form-check ps-0">
                                                    <i className={`bi bi${this.checkAnswer(item) ? '-check-' : '-'}square me-2`} />
                                                    {/* <input className="form-check-input" type="checkbox" id={'qans' + i} checked={this.checkAnswer(item)} value={item}
                                                       ></input> */}
                                                    <label className="form-check-label" htmlFor={'qans' + i}>{item}</label>
                                                </div>
                                            </li>
                                        })}

                                    </ul>
                                </div>
                            }
                            {/* Account Setup Step 1 End */}
                      
                            {/* Account Setup Step 2 End */}
                            <div className="modal-footer">
                                <button type="button" disabled={this.state.answer === ''} className={`btn btn-${props.modalData.resType} ${props.modalData.resType !== 'light' ? 'text-light' : 'text-dark'}`}
                                    onClick={() => {
                                        props.next({
                                            answers: this.state.answers,
                                            category: this.state.category
                                        });
                                        this.setState({
                                            answers: []
                                        })
                                    }}>{props.modalData.question.c==='Learninng'?'Finish':'Next'}</button>
                                {/* <button type="button" className="btn btn-next">Next</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export class ContactSFModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            phone: '',
        }
    }

render() {
    const props = this.props;
    return (
        <React.Fragment>
            <div className="modal">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content account-setup">
                        <div className="modal-header">
                            <div className="modal-title">
                                <h5><span className="text-capitalize">Setup your Account</span></h5>
                                <h6>Questions are asked to confirm the identity of the Course Learner.</h6>
                            </div>
                            <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}></button>
                        </div>
                     
                        {/* Account Setup Step 1 End */}
                        {/* Account Setup Step 2 Start */}
                            <div className="modal-body">
                                <h4> Please provide your Phone Number</h4>
                                <form className="step-contact">
                                    <div className="input-group">
                                        {/* <span className="input-group-text"> */}
                                            <input style={{width:'200px', flex:'unset'}} type="tel" pattern="/[0-9]{3}*/" minLength="1" maxLength="3" value={this.state.cc} className="form-control" placeholder="Country Code" onChange={(e)=>{this.setState({cc:e.target.value})}}/>
                                        {/* </span> */}
                                        <input type="tel" pattern="/[6-9]{3}[0-9]*/" minLength="10" maxLength="10" value={this.state.phone} className="form-control" placeholder="Your phone" onChange={(e)=>{this.setState({phone:e.target.value})}}/>
                                    </div>
                                </form>
                            </div>
                        
                        {/* Account Setup Step 2 End */}
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.cc === '' || this.state.phone === ''} className={`btn btn-primary text-light`}
                                onClick={() => {
                                    props.update({
                                        cc: this.state.cc,
                                        phone: this.state.phone
                                    });
                                    
                                }}>Finish</button>
                            {/* <button type="button" className="btn btn-next">Next</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
}

