import React from 'react';
import Vimeo from '@vimeo/player';
import api from '../../../services/Api/Api';
import './vimeoStyle.scss';

/**
 * Vimeo Player reference
 * https://developer.vimeo.com/player/sdk/reference
 * 
 *   id={this.state.intro_video}
        options={{
            width: '620',
            byline: false, // vimeo owner's name
            color: 'ff0000',
            height: '360',
            responsive: false,
            quality: 'auto',
            portrait: false,
            playsinline: true,
            transparent: false,
            // maxheight:'350'
        }}
        playerEvent={(data) => { this.playerEvent(data) }}
 * 
 */


class VimeoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vlink: '',
            transcodeMessage: '',
            playerEvent: {},
            play: false,
            counter: 0,
            id: this.props.id
        }
    }
    componentDidMount() {
        if (this.props.id !== '') {
            this.getVideo();
        }
    }
    videoProgress = (data) => {
        data.type = 'update'
        this.props.playerEvent(data);
    }
    videoEnded = (data) => {
        data.type = 'ended'
        this.props.playerEvent(data);
    }
    getVideo() {
        api.checkTranscoding({ vid: this.props.id }).then(res => {
            if (res.data.resCode === 200) {
                this.setState({
                    transcodeMessage: '',
                    play: true
                }, () => {
                    let currTime=this.props.currentChapaterCompletion>=98?0:this.props.currentChapaterCompletedDuration;
                    let uriarr = this.props.id.split('/');
                    let vid = uriarr[uriarr.length - 1];
                    let option = {
                        // url: "https://player.vimeo.com/video/76979871?h=8272103f6e", // do not delete this commented line
                        url: `https://player.vimeo.com/video/${vid}`
                    };
                    let propOptions = this.props.options;
                    let options = { ...option, ...propOptions }
                    options.color = '008bcc';
                    let preId = this.props.preId ? this.props.preId : 'myVideo';
                    let vp = new Vimeo(preId + vid, options);
                    vp.getBuffered().then(function(buffered) {
                        if(currTime)
                        vp.setCurrentTime(currTime)
                    }).catch(function(error) {
                        // an error occurred
                    });
                    vp.on('ended', this.videoEnded);
                    vp.on('timeupdate', (e)=>{
                        this.videoProgress(e);
                        if(e.seconds < currTime + 1 && e.seconds > currTime) {
                            // Above is where I hack it.  I only update the current time if the timeupdate is less than a second ago (therefore proabaly not seeked to).  This stops the function from just updating currTime to the seeked time.  But I feel it's not the best way.
                                currTime = e.seconds;
                            }
                    });
                    vp.on('seeked', function(e) {
                        if (e.seconds > currTime) {
                            vp.setCurrentTime(currTime)
                        } 
                    });
                    
                })
            }
            if (res.data.resCode === 100) {
                this.setState({
                    transcodeMessage: res.data.resMessage
                })
            }
        }).catch(err => {
            console.log('video transcode err', err);
        })
    }


    render() {
        return (
            <React.Fragment>
                {this.props.id !== '' && this.props.id &&
                    <>
                        <div id={`${this.props.preId ? this.props.preId : 'myVideo'}${this.props.id.split('/')[this.props.id.split('/').length - 1]}`}
                            className="myVideo" onMouseOver={(e) => {
                                if (this.props.isPlayOnOver) {
                                    let preId = this.props.preId ? this.props.preId : 'myVideo';
                                    let vp = new Vimeo(preId + this.props.id.split('/')[this.props.id.split('/').length - 1]);
                                    vp.play()
                                }
                            }}
                            style={{ height: this.props.options.height ? this.props.options.height + 'px' : null }}
                            onMouseOut={(e) => {
                                if (this.props.isPlayOnOver) {
                                    let preId = this.props.preId ? this.props.preId : 'myVideo';
                                    let vp = new Vimeo(preId + this.props.id.split('/')[this.props.id.split('/').length - 1]);
                                    vp.pause()
                                }
                            }}
                        >
                            {this.state.transcodeMessage !== '' && <span className="text-secondary" style={{ fontSize: '12px' }}>{this.state.transcodeMessage}. <button type="button" className="btn btn-primary" style={{ fontSize: '12px' }} onClick={(e) => { this.getVideo() }}> <i className="bi bi-arrow-clockwise mx-2" />refresh</button></span>}
                        </div>
                    </>
                }
                {(this.props.id === '' || !this.props.id) &&
                    <span>Loading ...</span>
                }

            </React.Fragment>
        )
    }
}

export default VimeoPlayer;
