import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';

import nwsltrImg from '../../../assets/images/nwsltr-header.jpg';
import nwsltrImg3 from '../../../assets/images/nwsltr-header-2.jpg';
import nwsltrImg4 from '../../../assets/images/nwsltr-header-3.jpg';
import nwsltrImg5 from '../../../assets/images/nwsltr-header-4.jpg';
import nwsltrImg6 from '../../../assets/images/nwsltr-header-5.jpg';
import nwsltrImg1 from '../../../assets/images/nwsltr-img1.jpg';
import nwsltrImg2 from '../../../assets/images/nwsltr-img2.jpg';
import linkedIn from '../../../assets/images/linkedin.png';
import youTube from '../../../assets/images/youtube.png';
import bookIcon from '../../../assets/images/book-icon.png';
import industrialIcon from '../../../assets/images/industrial-icon.png';
import agricultureIcon from '../../../assets/images/agriculture-icon.png';
import energyIcon from '../../../assets/images/energy-icon.png';
import transportIcon from '../../../assets/images/transport-icon.png';
import mediaIcon from '../../../assets/images/media-icon.png';

import dataIcon from '../../../assets/images/data-icon.png';
import healthIcon from '../../../assets/images/health-icon.png';
import realEstateIcon from '../../../assets/images/realestate-icon.png';
import retailIcon from '../../../assets/images/retail-icon.png';
import trainingIcon from '../../../assets/images/training-icon.png';
import medicalIcon from '../../../assets/images/medical-icon.png';
import hospitalityIcon from '../../../assets/images/hospitality-icon.png';
import ititesIcon from '../../../assets/images/itites-icon.png';
import publicIcon from '../../../assets/images/public-icon.png';
import financeIcon from '../../../assets/images/finance-icon.png';
import professionalIcon from '../../../assets/images/professional-icon.png';
import othersIcon from '../../../assets/images/others-icon.png';
import devIcon from '../../../assets/images/development-icon.png';
import businessIcon from '../../../assets/images/business-icon.png';
import officeIcon from '../../../assets/images/office-icon.png';
import musicIcon from '../../../assets/images/music-icon.png';
import projectmIcon from '../../../assets/images/projectm-icon.png';

import personadevIcon from '../../../assets/images/personadev-icon.png';
import designIcon from '../../../assets/images/design-icon.png';
import lifestyleIcon from '../../../assets/images/lifestyle-icon.png';
import photovideoIcon from '../../../assets/images/photovideo.png';
import marketingIcon from '../../../assets/images/marketing-icon.png';



import VimeoPlayer from '../../shared/vimeoplayer/vimeoplayer';

export class Modal extends React.Component {
    constructor(props) {
        super(props);
        this.element = document.createElement('div');
        this.element.setAttribute('class', 'modal-elem');
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.element
        )
    }

}

class Demo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: '',
            answers: [],
            category: ''
        }
    }

    render() {
        const props = this.props;
        return (
            <React.Fragment>
                <div className="page-content bg-smild-grey pb-30">
                    <div className="container">
                        <div className='notificationBlock'>
                            {/* <div className='nBlock'>
                                <span className='nNumber'>1.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                                <p>Blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>2.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs.</p>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>4.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>3.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <ul>
                                    <li>The charms of pleasure of the moment, so blinded by desire, that they cannot foresee.</li>
                                    <li>Pleasure of the moment, so blinded by desire.</li>
                                    <li>Charms of pleasure of the moment, that they cannot foresee so blinded by desire.</li>
                                </ul>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>5.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>6.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div> */}
                        
                        
                        
                        
                                                                      <VimeoPlayer
                                                                        id={'/videos/766398460'}
                                                                        options={{
                                                                            width: '620',
                                                                            byline: false, // vimeo owner's name
                                                                            color: 'ff0000',
                                                                            height: '360',
                                                                            responsive: false,
                                                                            quality: 'auto',
                                                                            portrait: false,
                                                                            playsinline: true,
                                                                            transparent: false,
                                                                            // maxheight:'350'
                                                                        }}
                                                
                                                                        />
                        
                        
                        
                        
                        </div>
                       
                    </div>


                    <div className="modal modal-signup">
                    <div className="modal-dialog modal-lg modal-dialog-centered">

                        <div className="modal-content account-setup">
                            <div className="modal-header">
                                <div className="modal-title">
                                    <h5><span className="text-capitalize">Setup Your Account</span></h5>
                                    <h6>Questions are asked to confirm the identity of the Course Learner.</h6>
                                </div>
                                <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}></button>
                            </div>
                            {/* Account Setup Step 1 Start */}
                            
                                <div className="modal-body">
                                    <h4>What industry do you belong to?</h4>

                                    <div className='allCategories'>
                                        <ul>
                                            <li>
                                                <a href='#' title='Agriculture, Forestry, Mining'>
                                                    <span className='catgImgCircle'><img src={agricultureIcon}></img></span>
                                                    <span className='catgTitle'>Agriculture, Forestry, Mining</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Industrials (Manufacturing, Construction, etc.)'>
                                                    <span className='catgImgCircle'><img src={industrialIcon}></img></span>
                                                    <span className='catgTitle'>Industrials (Manufacturing, Construction, etc.)</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Energy, Utilities'>
                                                    <span className='catgImgCircle'><img src={energyIcon}></img></span>
                                                    <span className='catgTitle'>Energy, Utilities</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Transport, Logistics'>
                                                    <span className='catgImgCircle'><img src={transportIcon}></img></span>
                                                    <span className='catgTitle'>Transport, Logistics</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='' title='Media, Creative Industries'>
                                                    <span className='catgImgCircle'><img src={mediaIcon}></img></span>
                                                    <span className='catgTitle'>Media, Creative Industries</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Data Infrastructure, Telecom'>
                                                    <span className='catgImgCircle'><img src={dataIcon}></img></span>
                                                    <span className='catgTitle'>Data Infrastructure, Telecom</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Healthcare'>
                                                    <span className='catgImgCircle'><img src={healthIcon}></img></span>
                                                    <span className='catgTitle'>Healthcare</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Real Estate and Constructions'>
                                                    <span className='catgImgCircle'><img src={realEstateIcon}></img></span>
                                                    <span className='catgTitle'>Real Estate and Constructions</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Education'>
                                                    <span className='catgImgCircle'><img src={bookIcon}></img></span>
                                                    <span className='catgTitle'>Education</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Training Services'>
                                                    <span className='catgImgCircle'><img src={trainingIcon}></img></span>
                                                    <span className='catgTitle'>Training Services</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>~
                                                <a href='#' title='Life and Medical Sciences'>
                                                    <span className='catgImgCircle'><img src={medicalIcon}></img></span>
                                                    <span className='catgTitle'>Medical Sciences</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Retail / Ecommerce'>
                                                    <span className='catgImgCircle'><img src={retailIcon}></img></span>
                                                    <span className='catgTitle'>Retail/Ecommerce</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Hospitality, Food, Leisure Travel'>
                                                    <span className='catgImgCircle'><img src={hospitalityIcon}></img></span>
                                                    <span className='catgTitle'>Hospitality</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='IT, ITES'>
                                                    <span className='catgImgCircle'><img src={ititesIcon}></img></span>
                                                    <span className='catgTitle'>IT, ITES</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Public Service, Social Service'>
                                                    <span className='catgImgCircle'><img src={publicIcon}></img></span>
                                                    <span className='catgTitle'>Public Service</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Financial Services'>
                                                    <span className='catgImgCircle'><img src={financeIcon}></img></span>
                                                    <span className='catgTitle'>Financial Services</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Professional Services (Law, Consulting, etc.)'>
                                                    <span className='catgImgCircle'><img src={professionalIcon}></img></span>
                                                    <span className='catgTitle'>Professional Services</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Other'>
                                                    <span className='catgImgCircle'><img src={othersIcon}></img></span>
                                                    <span className='catgTitle'>Other</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <h4>What categories of learning would interest you?</h4>

                                    <div className='allCategories'>
                                        <ul>
                                            <li>
                                                <a href='#' title='Development'>
                                                    <span className='catgImgCircle'><img src={devIcon}></img></span>
                                                    <span className='catgTitle'>Development</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Business'>
                                                    <span className='catgImgCircle'><img src={businessIcon}></img></span>
                                                    <span className='catgTitle'>Business</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Finance & Accounting'>
                                                    <span className='catgImgCircle'><img src={financeIcon}></img></span>
                                                    <span className='catgTitle'>Finance & Accounting</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='IT & Software'>
                                                    <span className='catgImgCircle'><img src={devIcon}></img></span>
                                                    <span className='catgTitle'>IT & Software</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Project Management'>
                                                    <span className='catgImgCircle'><img src={projectmIcon}></img></span>
                                                    <span className='catgTitle'>Project Management</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Office Productivity'>
                                                    <span className='catgImgCircle'><img src={officeIcon}></img></span>
                                                    <span className='catgTitle'>Office Productivity</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Personal Development'>
                                                    <span className='catgImgCircle'><img src={personadevIcon}></img></span>
                                                    <span className='catgTitle'>Personal Development</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Design'>
                                                    <span className='catgImgCircle'><img src={designIcon}></img></span>
                                                    <span className='catgTitle'>Design</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Marketing'>
                                                    <span className='catgImgCircle'><img src={marketingIcon}></img></span>
                                                    <span className='catgTitle'>Marketing</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' className='selected' title='Lifestyle'>
                                                    <span className='catgImgCircle'><img src={lifestyleIcon}></img></span>
                                                    <span className='catgTitle'>Lifestyle</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Photograph & Video'>
                                                    <span className='catgImgCircle'><img src={photovideoIcon}></img></span>
                                                    <span className='catgTitle'>Photograph & Video</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Health & Fitness'>
                                                    <span className='catgImgCircle'><img src={healthIcon}></img></span>
                                                    <span className='catgTitle'>Health & Fitness</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Music'>
                                                    <span className='catgImgCircle'><img src={musicIcon}></img></span>
                                                    <span className='catgTitle'>Music</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='#' title='Teaching & Academics'>
                                                    <span className='catgImgCircle'><img src={trainingIcon}></img></span>
                                                    <span className='catgTitle'>Teaching & Academics</span>
                                                    <div class="circle">
                                                        <div class="checkmark"></div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className='d-none'>Test</li>
                                        </ul>
                                    </div>

                                    {/* <h4>Please provide your contact number.</h4>

                                    <div className='allCategories'>
                                        <div className='inputFormField phoneNumberEntry'>
                                            <div className="input-group">
                                                
                                                    <select className='form-control formSelect'>
                                                        <option>IND</option>
                                                        <option>USA</option>
                                                        <option>CND</option>
                                                        <option>BNG</option>
                                                    </select>
                                                
                                                <input type="text" className="form-control" placeholder='Enter your phone number'></input>
                                            </div>
                                        </div>
                                    </div> */}
                                    
                                </div>
                           
                            {/* Account Setup Step 1 End */}
                      
                            {/* Account Setup Step 2 End */}
                            <div className="modal-footer">
                                <button type="button" className='btn btn-next'>Submit</button>
                                {/* <button type="button" className="btn btn-next">Next</button> */}
                            </div>
                        </div>
                    </div>
                </div>                                                       

                </div>
            </React.Fragment>
        )
    }
}

export default Demo;