import React, { Component } from 'react';
import './setPassword.scss';
import axllearnlogo from '../../../assets/images/logo-black.png';
import sadEmoji from '../../../assets/images/very-sad.png';

import { Modal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';

import api from '../../../services/Api/Api';
import { isValidPassword, validateEmail } from '../../../utils/constant';
import { NavLink } from 'react-router-dom';
import './setPassword.scss';


class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            resMessage: '',
            resType: '',
            errors: {},
            email: '',
            linkedExpired: false,
            fullname: "",
            password: "",
            cpassword: "",
            termsConditionFlag: false,
        }
        this.isEnableSignUpButton = this.isEnableSignUpButton.bind(this)
    }

    // componentDidMount() {
    //     const token = window.location.href.split('=')[1];
    //     const body = { pwtoken: token }
    //     this.checkValiditySignupLink(body)
    // }

    signUp(e) {
        e.preventDefault();

        let form = e.target;
        let data = {};

        if (!((/^(?!\s*$).+/).test(form.fullname.value.trim()))) {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    fullname: "Name is required"
                }
            }))
            return;
        }
       

        if (!validateEmail(form.email.value.trim())){
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    email: "Email is required"
                }
            }))
            return;
        }

        if (!isValidPassword(form.password.value) || form.cpassword.value !== form.password.value) {
            if (!isValidPassword(form.password.value)) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        password: "Password must be mixed with uppercase, number, special characters & with min 8 characters"
                    }
                }))
            }
            else {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        cpassword: "Confirm password must be same as above"
                    }
                }))
            }
            return;
        }

        let fullname = form.fullname.value.trim().split(" ");
        let firstName = '';
        let lastName = '';

        if (fullname.length === 1) {
            firstName = fullname[0].trim().charAt(0).toUpperCase() + fullname[0].trim().slice(1);
            lastName = firstName;
        }

        else {
            for (let i = 0; i < fullname.length - 1; i++) {
                firstName = firstName + " " + fullname[i].trim().charAt(0).toUpperCase() + fullname[i].trim().slice(1);
            }
            firstName = firstName.trim();

            lastName = fullname[fullname.length - 1].trim().charAt(0).toUpperCase() + fullname[fullname.length - 1].trim().slice(1);
        }

        data['email'] = form.email.value;
        data['password'] = form.password.value;
        data['profile'] = { firstName, lastName }


        this.setState({
            isLoading: true
        }, () => {
            api.signUp(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        isLoading: false,
                        errors: res.data.errors,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
            }).catch(err => {
                this.setState({
                    isLoading: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: 'danger'
                })
            })
        })
    }

    handleChange(e) {
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [e.target.name]: ''
            },
            [e.target.name]: e.target.value
        }))
    }

    // checkValiditySignupLink(payload) {
    //     api.checkSignUpLinkValidity(payload).then(res => {
    //         if (res.data.resCode === 200) {
    //             this.setState({
    //                 isLoading: false,
    //                 email: res.data.email
    //             })
    //         } else {
    //             this.setState({
    //                 isLoading: false,
    //                 linkedExpired: true,
    //                 resMessage: res.data.resMessage,
    //                 resType: res.data.resType
    //             })
    //         }
    //     }).catch(err => {
    //         this.setState({
    //             isLoading: false,
    //             resMessage: "Something went wrong! Please try again",
    //             resType: 'danger'
    //         })
    //     })
    // }

    isEnableSignUpButton() {
        if (!this.state.termsConditionFlag || this.state.fullname.length === 0 || this.state.password.length === 0 || this.state.cpassword.length === 0)
            return true
        else return false
    }

    render() {
        const apiDialog = this.state.resMessage !== '' ? <Modal>
            <div className={`text-center alert alert-${this.state.resType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
                <p>{this.state.resMessage}</p>
                <br />
                <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '' }) }}>Okay</button>
            </div>
        </Modal> : null;

        const loader = this.state.isLoading ? <Modal>
            <Loader message={"Loading..."} />
        </Modal> : null;
        return (
            <React.Fragment>

                <div className="signupContainer setPassword">
                    <div className="innerContainer">
                    <a href="/#" className="signupLogo">
                     <img src={axllearnlogo} alt="///"></img>
                            </a>
                    <div className="signupBox"> 
                            <div className="signinForm">
                                <div className='registerInn'>
                                    <h2>Create your Account</h2>
                                    <div className="fieldContainer">
                                        <form onSubmit={(e) => this.signUp(e)}>
                                            <div className="fieldRow input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-person"></i></span>
                                                <input type="fullname" name="fullname" value={this.state.fullname} className={`form-control ${this.state.errors.fullname && this.state.errors.fullname !== '' ? 'border-danger' : ''}`} id="fullname" placeholder="Full Name"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                                <small className="text-danger error-mgs">{this.state.errors.fullname && this.state.errors.fullname !== '' ? <>{this.state.errors.fullname}</> : ''}</small>
                                            </div>

                                            <div className="fieldRow input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope"></i></span>
                                                <input className="form-control" type="text" name="email" value={this.state.email} className={`form-control ${this.state.errors.email && this.state.errors.email !== '' ? 'border-danger' : ''}`} id="email" placeholder="Email"
                                                onChange={this.handleChange.bind(this)}/>
                                                                                                <small className="text-danger error-mgs">{this.state.errors.email && this.state.errors.email !== '' ? <>{this.state.errors.email}</> : ''}</small>
                                            </div>
                                            <div className="fieldRow input-group">
                                                <span className='pwd-hint'><i class="bi bi-info-circle-fill" title=''></i>
                                                    <span className='hint-content'>Your password must be mixed with uppercase, number, special characters & with min 8 characters.</span>
                                                </span>
                                                <span className='pwd-view'><i class="bi bi-eye-slash"></i></span>
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-shield-lock"></i></span>
                                                <input type="password" name="password" value={this.state.password} className={`form-control ${this.state.errors.password && this.state.errors.password !== '' ? 'border-danger' : ''}`} id="password" placeholder="Password"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                                <small className="text-danger error-mgs">{this.state.errors.password && this.state.errors.password !== '' ? <>{this.state.errors.password}</> : ''}</small>
                                            </div>
                                            <div className="fieldRow input-group">
                                                <span className='pwd-hint'><i class="bi bi-info-circle-fill" title=''></i>
                                                    <span className='hint-content'>It must be same as above password.</span>
                                                </span>
                                                <span className='pwd-view'><i class="bi bi-eye-slash"></i></span>
                                                <span className="input-group-text" id="basic-addon1"><i className="bi bi-shield-lock"></i></span>
                                                <input type="password" name="cpassword" value={this.state.cpassword} className={`form-control ${this.state.errors.cpassword && this.state.errors.cpassword !== '' ? 'border-danger' : ''}`} id="cpassword" placeholder="Confirm Password"
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                                <small className="text-danger error-mgs">{this.state.errors.cpassword && this.state.errors.cpassword !== '' ? <>{this.state.errors.cpassword}</> : ''}</small>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={this.state.termsConditionFlag} onChange={() => this.setState({ termsConditionFlag: !this.state.termsConditionFlag })} />
                                                <label className="form-check-label">Accept</label>
                                                <a href="/read_terms_and_condtion" target="_blank" rel="noreferrer noopener" className='termsC'>Terms &amp; Conditions</a>
                                            </div>

                                            <div className="fieldRow">
                                                <p><button type="submit" disabled={this.isEnableSignUpButton()} className="linkSignin">Sign Up</button></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                   </div>
                         <div className="signinBox">
                            <div className="signupInn">
                                <h2>Hello, Learner!</h2>
                                <p>Enter some of your details and start journey with us. </p>
                                <NavLink className="linkSignup" to="/login">SIGN IN</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                {apiDialog}
                {loader}
            </React.Fragment>

        )
    }
}

export default SetPassword;