import React, { Component, lazy, Suspense } from 'react';
import { NavLink, Link } from "react-router-dom";
import './courseDetails.scss';
import Rating from '@material-ui/lab/Rating';


import api from '../../../services/Api/Api';
import { v4 as uuidv4 } from 'uuid';
// import VimeoPlayer from '../../shared/vimeoplayer/vimeoplayer';
import { Modal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';
import Reviewer from '../../shared/Reviewer/Reviewer';
import LoginModule from '../../shared/loginmodule/loginmodule';
import RatingComp from '../../shared/RatingComp/RatingComp';

const VimeoPlayer = lazy(() => import("../../shared/vimeoplayer/vimeoplayer"));
const RelatedCourse = lazy(() => import("./RelatedCourse/RelatedCourse"));


class CourseDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            ratingLoader: false,
            resMessage: '',
            resType: '',
            resTitle: '',
            resCode: '',
            // ------------
            course: null,
            info: {},
            userId: '',
            usersCourses: [],
            intro_video: '',
            displayLoginModule: false,

            ratingNumber: null,
            reviewSection: false,
            reviewText: '',
            active: false,
            industries: [
                {
                    "label": "Agriculture, Forestry, Mining",
                    "value": "agriculture"
                },
                {
                    "label": "Industrials (Manufacturing, Construction, etc.)",
                    "value": "industrials"
                },
                {
                    "label": "Energy, Utilities",
                    "value": "energy"
                },
                {
                    "label": "Transport, Logistics",
                    "value": "transport"
                },
                {
                    "label": "Media, Creative Industries",
                    "value": "media"
                },
                {
                    "label": "Data Infrastructure, Telecom",
                    "value": "datainfra"
                },
                {
                    "label": "Healthcare",
                    "value": "healthcare"
                },
                {
                    "label": "Real Estate and Constructions",
                    "value": "realestate"
                },
                {
                    "label": "Education",
                    "value": "education"
                },
                {
                    "label": "Training Services",
                    "value": "rainingServices"
                },
                {
                    "label": "Life and Medical Sciences",
                    "value": "life"
                },
                {
                    "label": "Retail / Ecommerce",
                    "value": "retail"
                },
                {
                    "label": "Hospitality, Food, Leisure Travel",
                    "value": "hospitality"
                },
                {
                    "label": "IT, ITES",
                    "value": "it"
                },
                {
                    "label": "Public Service, Social Service",
                    "value": "publicService"
                },
                {
                    "label": "Financial Services",
                    "value": "financial"
                },
                {
                    "label": "Professional Services (Law, Consulting, etc.)",
                    "value": "professionaService"
                },
                {
                    "label": "Other",
                    "value": "other"
                }
            ],
        }
        this.handleCloseLoginModal = this.handleCloseLoginModal.bind(this)
    }

    componentDidMount() {
        this.getCourse();
    }

    getCourse() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            resTitle: '',
            resCode: ''
        }, () => {
            api.courseDetails({ courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] }).then(res => {

                if (res.data.resCode === 200) {
                    this.setState({
                        course: res.data.data,
                        resCode: res.data.resCode,
                        userId: res.data.userId,
                        userType: res.data.userType,
                        usersCourses: res.data.usersCourses,
                        intro_video: res.data.data.introVideo
                    }, () => {
                        this.setState({
                            loader: false,
                            info: {
                                tl: (() => {
                                    let totalLength = 0;
                                    res.data.data.content.chapters.forEach((ch) => {
                                        let tl = 0;

                                        ch.videos ? ch.videos.forEach(cv => {
                                            tl = tl + Number((cv.duration.toFixed(2)))
                                        }) : tl = 0
                                        totalLength = totalLength + tl;
                                    })
                                    return totalLength;
                                })()
                            },
                            ratingObject: this.checkRatingAuth()
                        }, () => {
                            let reqQuery = {
                                query: { status: 'published', author: this.state.course.author._id },
                                limit: 10,
                                skip: 0
                            }
                            api.courseSearch(reqQuery).then(res => {
                                if (res.data.resCode === 200) {
                                    this.setState({
                                        authoursTotalCourses: res.data.data.total
                                    })
                                }
                            }).catch((err) => {
                                // set_resMessage("Something wrong is there, please reload after sometime");

                            })
                        })
                    })
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle,
                        resCode: res.data.resCode
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong. Please reload the page',
                    resType: 'danger',
                    resTitle: 'Error',
                })
            })
        })
    }

    createMarkup(content) {
        return { __html: content };
    }

    sum(arr, key) {
        return arr.reduce((a, b) => a + (b[key] || 0), 0);
    }

    updateCourse(val) {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.updateCourse({ data: { status: val }, courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] }).then(res => {
                if (res.data.resCode === 200) {
                    this.setState(prev => ({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        course: {
                            ...prev.course,
                            status: val
                        }

                    }))
                    //this.props.profile(courseData);
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })
    }

    addCourseToMyLibrary() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            resTitle: '',
            resCode: ''
        }, () => {
            let finalAssesmentAnswerIdx={}
            if(this.state.course.content.finalAssesment){
           this.state.course.content.finalAssesment.values.forEach((_,i)=>finalAssesmentAnswerIdx[i]=[]);
            }
            let reqData = {
                courseObject: {
                    id: this.state.course._id,
                    actionPlanFlag: true,
                    isFinalAssessmentCompleted: false,
                    finalAssessmentScore: 0,
                    finalAssesmentAnswerIdx,
                    courseName: this.state.course.title,
                    endDate:null,
                    status: (() => {
                        let progressArr = [];
                        this.state.course.content.chapters.forEach(ch => {
                            let videos_arr = [];
                            if (ch.videos) {
                                ch.videos.forEach(vitem => {
                                    videos_arr.push({
                                        name: vitem.name,
                                        duration: vitem.duration,
                                        completed: 0
                                    })
                                });
                            }
                            if (ch.quizzes) {
                                ch.quizzes.forEach(vitem => {
                                   let obj={}
                                    vitem.values.forEach((_,i)=>obj[i]=[])
                                    videos_arr.splice(vitem.position||0,0,{
                                        name: vitem.name,
                                        score:0,
                                        completed: 0,
                                        quizAnswerIdx:obj
                                    })
                                });
                            }
                            progressArr.push({
                                chapterId: ch.id,
                                progress: videos_arr
                            })
                        })
                        return progressArr;
                    })()
                }
            }
            api.addCourse(reqData).then((res) => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle,
                        resCode: res.data.resCode,
                        usersCourses: [...this.state.usersCourses, reqData.courseObject]
                    }, () => {
                        console.log('this.state', this.state);
                    })
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle,
                        resCode: res.data.resCode
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong. Please reload the page',
                    resType: 'danger',
                    resTitle: 'Error',
                })
            })
        })
    }

    averageRatingCalculation() {
        let averageValue = 0;
        this.state.course.ratings.forEach(ratingItem => {
            averageValue = averageValue + ratingItem.value
        })
        return averageValue / this.state.course.ratings.length
    }

    checkRatingAuth() {
        let ratingObject = {
            value: 0,
            review: '',
            status: false
        }
        let userID = this.state.userId;
        let userIdRateIndex = this.state.course.ratings.map(item => { return item.userId }).indexOf(userID);
        if (userIdRateIndex > -1) {
            ratingObject.value = this.state.course.ratings[userIdRateIndex].value;
            ratingObject.review = this.state.course.ratings[userIdRateIndex].review;
            ratingObject.status = true;
        }
        return ratingObject;
    }

    updateRating(data) {
        let course = { ...this.state.course };
        let ratings = [...course.ratings];
        ratings.push(data);
        course.ratings = ratings;
        this.setState({
            ratingLoader: true
        }, () => {
            this.setState({
                ratingLoader: false,
                course: course,

            }, () => {
                this.setState({
                    ratingObject: this.checkRatingAuth()
                })
            })
        })

    }
    format(seconds) {
        if (isNaN(seconds)) {
            return `00:00`;
        }
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, "0");
        if (hh) {
            return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
        }
        return `${mm}:${ss}`;
        // console.log("date=",`${mm}:${ss}`)
    };

    handleCloseLoginModal() {
        this.setState({ displayLoginModule: !this.state.displayLoginModule })
    }

    render() {

        const displayLoginModule = this.state.displayLoginModule ? <Modal><LoginModule closeReviewModal={()=>this.handleCloseLoginModal()} /></Modal> : null;

        let course = this.state.course;
        let info = this.state.info;

        return (
            <React.Fragment>
                <div className="page-content">
                    {!this.state.loader && course !== null &&
                        <React.Fragment>
                            <div className="fluid-banner">
                                <h1>
                                    {this.state.userId !== '' && this.state.userId === course.author._id && <Link to={`/account/${this.state.userId}/CourseEdit/${course._id}`} className="btn btn-primary float-start"> <i className="bi bi-arrow-left me-2 " /> Back</Link>}
                                    {course.title}
                                    {this.state.userType && this.state.userType === 'admin' &&
                                        <>
                                            {this.state.course.status === 'submitted' &&
                                                <>
                                                    <button className="btn btn-danger float-end" onClick={() => this.updateCourse('rejected')}>Reject</button>
                                                    <button className="btn btn-primary me-1 float-end" onClick={() => this.updateCourse('published')}>Publish</button>
                                                </>
                                            }
                                            {this.state.course.status === 'published' &&
                                                <>
                                                    <button className="btn btn-danger float-end" onClick={() => this.updateCourse('inProgress')}>Revert</button>
                                                </>
                                            }
                                        </>
                                    }
                                </h1>
                            </div>
                            <div className="container">
                                <div className="page-bredcrumbs">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <ul className="top-bredcrumbs">
                                                <li><NavLink to={`/search?industry=${course.industry}`}>{this.state.industries.find(elem => elem.value === course.industry).label}</NavLink></li>
                                                <li><NavLink to={`/search?industry=${course.industry}&audience=${course.audience}`}>{course.audience}</NavLink></li>
                                                <li><NavLink to={`/search?industry=${course.industry}&audience=${course.audience}&category=${course.category}`}>{course.category}</NavLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="course-details">
                                    <div className="row row-axl">
                                        <div className="col-sm-8 col-xs-axl">
                                            <div className="course-details-left">
                                                <div className="main-thumbnail bg-light text-center">
                                                    {this.state.intro_video ?
                                                        <Suspense fallback={
                                                            <div>
                                                                Loading...
                                                            </div>
                                                        }>
                                                            <VimeoPlayer
                                                                id={this.state.intro_video}
                                                                options={{
                                                                    width: '620',
                                                                    byline: false, // vimeo owner's name
                                                                    color: 'ff0000',
                                                                    height: '360',
                                                                    responsive: false,
                                                                    quality: 'auto',
                                                                    portrait: false,
                                                                    playsinline: true,
                                                                    transparent: false,
                                                                    // maxheight:'350'
                                                                }}
                                                                playerEvent={(data) => { this.playerEvent(data) }} />
                                                        </Suspense>
                                                        :
                                                        course.poster ?

                                                            <img id={course.poster}
                                                                src={course.poster}
                                                                alt='///'
                                                                // src={this.getMedia(course.poster, course.poster)}
                                                                onError={(e) => {
                                                                    e.target.parentNode.className = e.target.parentNode.className + ' bg-secondary text-light w-100 placeholder';
                                                                    e.target.parentNode.innerText = course.title;
                                                                    e.target.style.display = 'none';
                                                                }}
                                                            /> :
                                                            <h3> Preview not available </h3>
                                                    }

                                                    <div className="thumb-details">
                                                        <div className="thumb-left">
                                                            <div className="author-details">
                                                                <span className="author-img">
                                                                    <img src={course.author.profile.profilePic}
                                                                        alt='///'
                                                                        onError={(e) => {
                                                                            e.target.parentNode.className = e.target.parentNode.className + ' bg-secondary text-light text-center';
                                                                            e.target.parentNode.innerText = (course.author.profile.firstName ? course.author.profile.firstName.charAt(0) : '-') + (course.author.profile.lastName ? course.author.profile.lastName.charAt(0) : '-');
                                                                            e.target.style.display = 'none';

                                                                        }} />
                                                                </span>
                                                                <span className="author-name"><span className="grey">By</span> {course.author.profile.firstName} {course.author.profile.lastName}</span>
                                                            </div>
                                                            {/* <div className="author-reviews">
                                                                <span className="rating-amount">4.6</span>
                                                                <span className="rating-star"><Rating name="size-small" defaultValue={4} size="small" /></span>
                                                                <span className="total-review">243 Review</span>
                                                            </div> */}
                                                        </div>
                                                        <div className="thumb-right">
                                                            {/* <span className="price">$349</span> */}
                                                            {/* <span className="purchase"><a href="#" className="btn btn-buynow">Buy Now</a></span> */}
                                                            {this.state.userId !== '' && this.state.userId !== course.author._id && this.state.userType !== 'admin' && this.state.usersCourses.map(item => { return item.id }).indexOf(course._id) === -1 &&
                                                                <span className="purchase">
                                                                    <button
                                                                        disabled={this.state.usersCourses.map(item => { return item.id }).indexOf(course._id) > -1}
                                                                        type="button" className="btn btn-buynow"
                                                                        onClick={() => {
                                                                            this.state.usersCourses.map(item => { return item.id }).indexOf(course.courseId) == -1 ? this.addCourseToMyLibrary() : console.log('')
                                                                        }}>
                                                                        {/* <i className="bi bi-plus" />*/} Add to Library</button> 
                                                                </span>
                                                            }
                                                            {this.state.userId !== '' && this.state.userId === course.author._id && <span className="purchase"><a href={`/courseplayer/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-buynow"> <i className="bi bi-play-circle-fill" /> Play Course</a></span>}
                                                            {this.state.userType === 'admin' && <span className="purchase"><a href={`/courseplayer/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-buynow"> <i className="bi bi-play-circle-fill" /> Play Course</a></span>}
                                                            {this.state.usersCourses.map(item => { return item.id }).indexOf(course._id) > -1 && <span className="purchase"><a href={`/courseplayer/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-buynow"> <i className="bi bi-play-circle-fill" /> Play Course</a></span>}
                                                           

                                                           {!this.state.userId && (<button type='button' className='btn btn-startCourse' onClick={() => this.setState({ displayLoginModule: !this.state.displayLoginModule })}>Start Course</button>)}
                                                            
                                                            {/* {this.state.displayLoginModule && <><LoginModule closeReviewModal={()=>this.handleCloseLoginModal()} /></>} */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-pos2">
                                                <div className="course-details-left">
                                                    {course.objectives && course.objectives !== '' &&
                                                        <div className="course-content">
                                                            <div className="contents-block">
                                                                <h3>Course Objective</h3>
                                                                <div className="dynamicContent" dangerouslySetInnerHTML={this.createMarkup(course.objectives)} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {course.description && course.description !== '' &&
                                                        <div className="course-content">
                                                            <div className="contents-block">
                                                                <h3>What you will learn</h3>
                                                                <div className="dynamicContent" dangerouslySetInnerHTML={this.createMarkup(course.description)} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {course.instruction && course.instruction !== '' &&
                                                        <div className="course-content">
                                                            <div className="contents-block">
                                                                <h3>Learning instruction</h3>
                                                                <div className="dynamicContent" dangerouslySetInnerHTML={this.createMarkup(course.instruction)} />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="course-content">
                                                        <div className="contents-block">
                                                            <h3 style={{paddingRight:'9px'}}>Course contents
                                                                <span className="lesson-details">
                                                                    <span className="total-lessons">{course.content.chapters.length} topic(s)</span>
                                                                    <span className="total-time">{this.format(info.tl)} min</span>
                                                                </span>
                                                            </h3>

                                                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                                                {course.content.chapters.map((ch, ci) => {
                                                                    return <div key={uuidv4()} className="accordion-item">
                                                                        <h2 className="accordion-header" id={`panelsStayOpen-${ci}-headingOne`}>
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-${ci}-collapseOne`} aria-expanded="true" aria-controls={`panelsStayOpen-${ci}-collapseOne`}>
                                                                                {ch.title}
                                                                                <span className="lesson-details">
                                                                                    <span className="total-lessons">{ch.videos ? ch.videos.length : 0} video(s)</span>
                                                                                    <span className="total-time">{ch.videos ? this.format(this.sum(ch.videos, 'duration').toFixed(2)) : '00'} min</span>
                                                                                </span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id={`panelsStayOpen-${ci}-collapseOne`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-${ci}-headingOne`}>
                                                                            <div className="accordion-body">
                                                                                <p>{ch.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                })}



                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* {!this.state.loader && this.state.ratingObject && !this.state.ratingObject.status && this.state.userId !== '' &&
                                                    <RatingComp course={this.state.course} ratingUpdate={(data) => this.updateRating(data)} />
                                                }
                                                {!this.state.loader && this.state.ratingObject && this.state.ratingObject.status && this.state.userId !== '' &&
                                                    <>
                                                        <Rating name="size-medium" defaultValue={this.state.ratingObject.value} size="medium" />
                                                        <p>{this.state.ratingObject.review}</p>
                                                    </>
                                                } */}
                                                    <div className="course-content">
                                                        <div className="contents-block">
                                                            <h3>You also might like</h3>
                                                            {!this.state.loader &&
                                                                <Suspense fallback={
                                                                    <div className="text-center mb-2">
                                                                        <div className="spinner-border text-primary" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                }>
                                                                    <RelatedCourse data={{ category: this.state.course.category, subcategory: this.state.course.subcategory, currentId: this.state.course._id }} />
                                                                </Suspense>
                                                            }

                                                        </div>
                                                    </div>
                                                </div >
                                            </div >
                                        </div>
                                        <div className="col-sm-4 col-xs-axl small-only">
                                            <div className="course-details-right">
                                                <div className="course-information">
                                                    {/* <div className="intro-video">
                                                        <img src={introVideoImg}></img>
                                                        <span className="play-btn"><img src={videoPlayImg}></img></span>
                                                    </div> */}
                                                    <h4 className="intro-title">Course Introduction</h4>
                                                    <div className="important-details">
                                                        <ul>
                                                            <li>
                                                                <span className="imp-lt"><i className="bi bi-files"></i> Total Topic</span>
                                                                <span className="imp-rt">{course.content.chapters.length}</span>
                                                            </li>
                                                            <li>
                                                                <span className="imp-lt"><i className="bi bi-clock"></i> Course Duration</span>
                                                                <span className="imp-rt">{this.format(this.state.info.tl)} min</span>
                                                            </li>
                                                            {/* <li>
                                                                <span className="imp-lt"><i className="bi bi-file-earmark-medical"></i> Articles</span>
                                                                <span className="imp-rt">13</span>
                                                            </li> */}
                                                            {/* <li>
                                                                <span className="imp-lt"><i className="bi bi-file-arrow-down"></i> Download Items</span>
                                                                <span className="imp-rt">26</span>
                                                            </li> */}
                                                            <li>
                                                                <span className="imp-lt"><i className="bi bi-speedometer2"></i> Course Level</span>
                                                                <span className="imp-rt text-capitalize">{this.state.course.level}</span>
                                                            </li>
                                                            {/* <li>
                                                                <span className="imp-lt"><i className="bi bi-chat-right-text"></i> Total Quiz</span>
                                                                <span className="imp-rt">13</span>
                                                            </li> */}
                                                            {/* <li>
                                                                <span className="imp-lt"><i className="bi bi-journal-text"></i> Final Assesment</span>
                                                                <span className="imp-rt">1</span>
                                                            </li> */}
                                                        </ul>
                                                        {/* <div className="btn-block">
                                                            <a href="#" className="btn btn-share"><i className="bi bi-share"></i> Share Course Link</a>
                                                            <button className="btn btn-addwish" onClick={() => {
                                                                this.setState({
                                                                    wishlistLoader: true,
                                                                    resMessage: '',
                                                                    resType: '',
                                                                    resTitle: ''
                                                                }, () => {
                                                                    let reqData = {
                                                                        courseId: this.state.course._id
                                                                    }
                                                                    api.addToFavourite(reqData).then(res => {
                                                                        if (res.data.resCode !== 400) {
                                                                            this.setState({
                                                                                wishlistLoader: false,
                                                                                resMessage: res.data.resMessage,
                                                                                resType: res.data.resType,
                                                                                resTitle: res.data.resTitle
                                                                            })
                                                                        } else {
                                                                            api.logout().then(res => {
                                                                                window.location.replace('/login')
                                                                            })
                                                                        }
                                                                    }).catch(err => {
                                                                        // console.log('wishlist error', err);
                                                                        this.setState({
                                                                            wishlistLoader: false,
                                                                            resMessage: 'Something went wrong',
                                                                            resType: 'danger',
                                                                            resTitle: 'Error'
                                                                        })
                                                                    })
                                                                })
                                                            }}><i className={`bi bi-heart`}></i> Add To Wishlist</button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="about-author">
                                                    <h4 className="about-title">About Author</h4>
                                                    <div className="author-intro">
                                                        <div className={`img-block ${!course.author.profile.profilePic ? 'bg-secondary text-light text-center' : ''}`}>
                                                            {course.author.profile.profilePic &&
                                                                <img src={course.author.profile.profilePic}
                                                                    alt='///'
                                                                    onError={(e) => {
                                                                        console.log('error on image');
                                                                        e.target.parentNode.className = e.target.parentNode.className + ' bg-secondary text-light text-center';
                                                                        e.target.parentNode.innerText = course.author.profile.firstName + course.author.profile.lastName;
                                                                        e.target.style.display = 'none';
                                                                    }} />
                                                            }
                                                            {!course.author.profile.profilePic &&
                                                                <>{course.author.profile.firstName.charAt(0) + course.author.profile.lastName.charAt(0)}</>
                                                            }

                                                        </div>
                                                        <div className="name-role">
                                                            <h5>{course.author.profile.firstName} {course.author.profile.lastName}</h5>
                                                            {/* <h6>{course.author.profile.tagline}</h6> */}
                                                            <h6>Courses : {this.state.authoursTotalCourses ? this.state.authoursTotalCourses : 0}</h6>
                                                            <span className="author-linkedin">
                                                                {course.author.profile.social.map(item => {
                                                                    return <a href={`https://${item.value}`} key={uuidv4()} target="_blank"><i className={`bi bi-${item.name.toLowerCase()} fs-6 mx-1`}></i></a>
                                                                })}
                                                            </span>
                                                            {/* <span className='author-linkedin'>
                                                                <a href='/#'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" focusable="false" class="lazy-loaded">
                                                                        <g class="scaling-icon">
                                                                            <g class="logo-21dp">
                                                                                <g class="dpi-1">
                                                                                    <g class="inbug" fill="none" fill-rule="evenodd">
                                                                                        <path d="M82.479 0H64.583C63.727 0 63 .677 63 1.511v17.977c0 .835.477 1.512 1.333 1.512h17.896c.857 0 1.771-.677 1.771-1.512V1.511C84 .677 83.336 0 82.479 0" class="bug-text-color" fill="#FFF"></path>
                                                                                        <path d="M82.479 0H64.583C63.727 0 63 .677 63 1.511v17.977c0 .835.477 1.512 1.333 1.512h17.896c.857 0 1.771-.677 1.771-1.512V1.511C84 .677 83.336 0 82.479 0zM71 8h2.827v1.441h.031c.431-.777 1.704-1.566 3.278-1.566C80.157 7.875 81 9.479 81 12.45V18h-3v-5.003c0-1.33-.531-2.497-1.773-2.497-1.508 0-2.227 1.021-2.227 2.697V18h-3V8zm-5 10h3V8h-3v10zm3.375-13.5a1.874 1.874 0 11-3.749.001 1.874 1.874 0 013.749-.001z" class="background" fill="currentColor"></path>
                                                                                    </g>
                                                                                </g></g></g></svg></a>
                                                            </span> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="additional-info">
                                                        <h6>Courses : {this.state.authoursTotalCourses ? this.state.authoursTotalCourses : 0}</h6>
                                                        <h6>Total viewed : 26</h6>
                                                        {!this.state.ratingLoader && <h6><Rating name="size-small" defaultValue={this.averageRatingCalculation()} size="small" /></h6>}
                                                    </div> */}
                                                    <div className="about-description">
                                                        <p>{course.author.profile.about}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small-pos1">
                                                <div className="course-content">
                                                    <div className="contents-block">
                                                        <div className="reviewRating">
                                                            {/* <p className="rating-img"><img src={ratingImg}></img></p> */}
                                                            <h2>Learners review & rating on this Course</h2>
                                                            {/* {!this.state.loader && this.state.ratingObject && !this.state.ratingObject.status && this.state.userId !== '' &&
                                                            <>
                                                                <RatingComp course={this.state.course} ratingUpdate={(data) => this.updateRating(data)} />
                                                                <p><i class="bi bi-pen"></i> Write your review</p>
                                                            </>
                                                        } */}
                                                            {!this.state.loader && this.state.ratingObject && this.state.ratingObject.status && this.state.userId !== '' &&
                                                                <>
                                                                    <Rating name="size-large" defaultValue={this.state.ratingObject.value} size="large" />
                                                                    {/* <p>{this.state.ratingObject.review}</p> */}
                                                                </>
                                                            }

                                                            <div className="all-reviews">
                                                                <h6>Average Rating</h6>
                                                                <Rating name="size-large"
                                                                    defaultValue={
                                                                        this.state.course.ratings.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0) / this.state.course.ratings.length
                                                                    } size="large" readOnly />
                                                                <p>Reviews from Learners</p>
                                                                {this.state.course.ratings.map((r_item, index) => {
                                                                    return <Reviewer key={uuidv4()} reviewData={r_item} />
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='clear-both'></div>
                                    <div className="row row-axl">


                                    </div >
                                </div >
                            </div >
                        </React.Fragment >
                    }
                </div>
                {/* {loader}
                {actionPlanDialog} */}
                {/* {courseCompletionDialog} */}
                {displayLoginModule}
            </React.Fragment >
        )
    }
}

export default CourseDetails;