import React, { Component } from 'react';
import Loader from '../../../shared/loader/loader';
import api from '../../../../services/Api/Api';
import Card from '../../../shared/Card/Card';
import { v4 as uuidv4 } from 'uuid';
import './Simulators.scss';

class Simulators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [
                {
                    poster:'https://cdn.pixabay.com/photo/2020/12/11/11/33/training-5822607_1280.png',
                    status:'published',
                    title:'Feedback',
                    id:'feedback'

                },
                {
                    poster:'https://cdn.pixabay.com/photo/2020/12/11/11/33/training-5822607_1280.png',
                    status:'published',
                    title:'Coaching',
                    id:'coaching'

                }
            ],

            // ------ api related -----
            loader: '',
            resMessage: '',
            resType: '',
            searchQuery: '',

            limit: 10,
            skip: 0,
            total: 0,
            page_num: 1
        }
    }

    

    render() {
        const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;
        const apiDialog = this.state.resMessage !== '' ?

            <div className={`course-not-found alert alert-${this.state.resType}`}>
                <p>{this.state.resMessage}</p>
                <i className="bi bi-x-square-fill position-absolute cross-icon" onClick={() => this.setState({ resMessage: '', resType: '' })}></i>
            </div>

            : null;
        return (
            <React.Fragment>
                <div className="page-content">
                    
                    <div className="container">
                        <div className="search-result">
                            <div className="row">
                                {this.state.list.map(item => {
                                    let info = {
                                        poster: item.poster,
                                        title: item.title,
                                        id: item.id,
                                        type:'sim'

                                    }
                                    return <div className="col-sm-3" key={uuidv4()}><Card type={item.status} info={info} delete={() => this.deleteCourse(info)} /></div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                
                {apiDialog}
                {loader}
            </React.Fragment>
        )
    }
}

export default Simulators;