import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./loginmodule.scss";
// import axllearnlogo from '../../../assets/images/logo-black.png';
import googleLogo from "../../../assets/images/google-icon.svg";

import { Modal } from "../../shared/modal/modal";
import Loader from "../../shared/loader/loader";
import api from "../../../services/Api/Api";
import { currentBackendBaseUrl } from "../../../utils/constant";


class LoginModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      formdata: [],
      resMessage: "",
      resType: "",
      resMessageAuth: "",
      resTypeAuth: "",
      submitErrorMessage: "",
      errors: {},
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    //  this.redirectToGoogleSSO = this.redirectToGoogleSSO.bind(this);
  }

  componentDidMount() {
    this.getLoginForm();
  }

  getLoginForm() {
    api
      .getForm("login")
      .then((res) => {
        if (res.data.resCode === 200) {
          console.log("formdata = ", res.data.data.fields);
          this.setState({
            loader: false,
            formdata: res.data.data.fields,
            resMessage: res.data.resMessage,
            resType: res.data.resType,
          });
        } else {
          this.setState({
            loader: false,
            resMessage: res.data.resMessage,
            resType: res.data.resType,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loader: false,
          resMessage: "Something went wrong! Please try again..",
          resType: "danger",
        });
      });
  }
  handleFormChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      resMessageAuth: "",
      resTypeAuth: "",
      errors: {
        ...prevState.errors,
        [e.target.name]: "",
      },
      formdata: prevState.formdata.map((obj) =>
        obj.name === name
          ? Object.assign(obj, {
              value: value,
              error: (() => {
                if (
                  (obj.validation.isEmptyString && value === "") ||
                  (obj.validation.isUndefined && !value) ||
                  (obj.validation.isRegex && !this.testRegex(obj.regex, value))
                )
                  return true;
                return false;
              })(),
            })
          : obj
      ),
    }));
  }

  testRegex = (reg, val) => {
    let regx = new RegExp(reg, "i");
    return regx.test(val);
  };
  parseQueryString() {
    let str = window.location.search;
    let objURL = {};
    str.replace(
      new RegExp("([^?=&]+)(=([^&]*))?", "g"),
      function ($0, $1, $2, $3) {
        objURL[$1] = $3;
      }
    );
    return objURL;
  }
  authenticate(e) {
    e.preventDefault();
    let logindata = {};
    let form = e.target;
    let submitError = false;
    let submitErrorMessage = "";

    this.setState(
      (prevState) => ({
        ...prevState,
        loader: true,
        formdata: prevState.formdata.map((obj, i) =>
          obj.name === form.elements[i].name
            ? Object.assign(obj, {
                error: (() => {
                  if (
                    (obj.validation.isEmptyString &&
                      form.elements[i].value === "") ||
                    (obj.validation.isUndefined && !form.elements[i].value) ||
                    (obj.validation.isRegex &&
                      !this.testRegex(obj.regex, form.elements[i].value))
                  )
                    return true;
                  return false;
                })(),
                errorMessage: (() => {
                  if (
                    (obj.validation.isEmptyString &&
                      form.elements[i].value === "") ||
                    (obj.validation.isUndefined && !form.elements[i].value) ||
                    (obj.validation.isRegex &&
                      !this.testRegex(obj.regex, form.elements[i].value))
                  )
                    return "Enter valid " + form.elements[i].name;
                  return "";
                })(),
              })
            : obj
        ),
      }),
      () => {
        this.state.formdata.forEach((item) => {
          logindata[item.name] = item.value;
          if (item.error) {
            submitError = true;
            submitErrorMessage = item.errorMessage;
            return;
          }
        });
        if (submitError) {
          console.error("Please enter correct input");
          this.setState({
            submitErrorMessage: submitErrorMessage,
            loader: false,
          });
          return;
        } else {
          this.setState(
            {
              submitErrorMessage: "",
              loader: false,
            },
            () => {
              // Redirect to home
              // this.props.history.push("/home") // history is undefined, have to check this error
              api.logIn(logindata).then((res) => {
                if (res.data.resCode === 200) {
                  if (this.parseQueryString().npw) {
                    window.location.replace(
                      "/account/mycourses?npw=" + this.parseQueryString().npw
                    );
                  } else {
                    window.location.replace("/");
                  }
                } else {
                  this.setState({
                    isLoading: false,
                    errors: res.data.error,
                    resMessageAuth: res.data.resMessage,
                    resTypeAuth: res.data.resType,
                  });
                }
              });
            }
          );
        }
      }
    );
  }

  redirectToGoogleSSO = async () => {
    const googleLoginURL = currentBackendBaseUrl + "/user/login/google";
    window.open(googleLoginURL, "_self", "width=500,height=600");
  };
  render() {
    const apiDialog =
      this.state.resMessage !== "" ? (
        <Modal>
          <div
            className={`text-center alert alert-${this.state.resType}`}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: "200px",
              height: "200px",
              margin: "auto",
              zIndex: 11,
            }}
          >
            <p>{this.state.resMessage}</p>
            <br />
            <button
              type="button"
              className={`btn btn-${this.state.resType}`}
              onClick={() => {
                this.setState({ resMessage: "", resType: "" });
              }}
            >
              Okay
            </button>
          </div>
        </Modal>
      ) : null;

    const loader = this.state.isLoading ? (
      <Modal>
        <Loader message={"Loading..."} />
      </Modal>
    ) : null;
    return (
      <React.Fragment>
        <div className="modal course-review-modal" id="staticBackdropLive" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="loginModuleContainer">
                <div className="innerContainer">
                  <div className="signinBox">
                    <div className="signinForm">
                      <h2 className="mt-4">Sign in to Axllearn</h2>
                      <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={() => this.props.closeReviewModal()}></button>
                      <div className="socialLogin d-none">
                        {/* <a href="#" className="fbSignin"><i className="bi bi-facebook"></i> <span>Facebbok</span></a> */}
                        <a
                          href="#"
                          className="gpSignin"
                          onClick={this.redirectToGoogleSSO}
                        >
                          <span className="google-icon">
                            <img alt="///" src={googleLogo}></img>Sign-In with
                            Google
                          </span>{" "}
                          <span className="arrow-right">
                            <i class="bi bi-arrow-right"></i>
                          </span>
                        </a>
                        {/* <a href="#" className="lnSignin"><i className="bi bi-linkedin"></i> <span>Linkedin</span></a> */}
                      </div>
                      <h6 className="d-none">or use your email account.</h6>
                      <div className="fieldContainer position-relative">
                        {this.state.resMessageAuth !== "" && (
                          <div
                            className={`alert alert-${this.state.resTypeAuth} p-0 text-center`}
                          >
                            <small>{this.state.resMessageAuth}</small>
                            <i
                              className="bi bi-x-circle float-end me-2 btn p-0 text-danger"
                              onClick={() => {
                                this.setState({
                                  resTypeAuth: "",
                                  resMessageAuth: "",
                                });
                              }}
                            ></i>
                          </div>
                        )}

                        <form
                          onSubmit={(e) => {
                            this.authenticate(e);
                          }}
                        >
                          {this.state.formdata.length > 0 && (
                            <>
                              {this.state.formdata.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="fieldRow input-group "
                                  >
                                    <span className="input-group-text">
                                      <i
                                        className={`${item.icon} ${
                                          (!this.testRegex(
                                            item.regex,
                                            item.value
                                          ) &&
                                            item.value !== "") ||
                                          item.error
                                            ? "text-danger"
                                            : ""
                                        }`}
                                      ></i>
                                    </span>
                                    <input
                                      type={item.type}
                                      className={`form-control`}
                                      name={item.name}
                                      id={item.id}
                                      placeholder={item.placeholder}
                                      onChange={this.handleFormChange}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {this.state.submitErrorMessage !== "" && (
                            <div className="fieldRow text-center">
                              <small className="text-danger">
                                {this.state.submitErrorMessage}
                              </small>
                            </div>
                          )}
                          <div className="fieldRow">
                            <p>
                              <a href={`/forgotPassword`}>
                                Forgot your password?
                              </a>
                            </p>
                          </div>

                          <div className="fieldRow">
                            <p>
                              {/* <NavLink className="linkSignin" to="/home">Sign In</NavLink> */}
                              <input
                                type="submit"
                                className="linkSignin"
                                value="Sign In"
                              />
                              <NavLink className="linkSignup" to="/signup">SIGN UP</NavLink>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         {apiDialog}
        {loader}
      </React.Fragment>
    );
  }
}

export default LoginModule;
