import React from 'react';
import Card from './ViewSomulationsCard';
import { v4 as uuidv4 } from 'uuid';
import './MySimulationsLibrary.scss';

const MySimulationsLibrary = () => {

    const mySimulationLibrary=[{category:'sim',title:'Simulation-1'},{category:'sim',title:'Simulation-2'},{category:'sim',title:'Simulation-3'},{category:'sim',title:'Simulation-4'}]
  return (
    <div className='simulations-library-page'> 
     <div className="page-header">
    <div className="row">
        <div className="col-sm-12">
            <div className="page-title">
                <h2>My Simulations</h2>
                {/* <h6>Add your new course or edit your existing course.</h6> */}
            </div>
        </div>

    </div>
</div>
     <div className="simulations-cards-section">
     <div className="row">
                        {mySimulationLibrary.map(item => {


                            return <div className="col-sm-3" key={uuidv4()}><Card data={item} /></div>



                        })}

                    </div>
     </div>
</div>
  )
}

export default MySimulationsLibrary