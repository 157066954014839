import React from 'react'
import img from '../../../assets/images/2.png'
import './MySimulationsLibrary.scss';

const ViewSomulationsCard = ({data:{title,category}}) => {
  return (
    <div className={`card-lg course-card inprogress`}>
    <div className="course-card-header">
        <img src={img}
            alt="///"
            onError={(e) => {
                e.target.parentNode.className = e.target.parentNode.className + ' placeholder default-poster';
                // e.target.parentNode.innerText = data.info.title;
                e.target.style.display = 'none';
            }}
        />
        <p>{category}</p>
    </div>

    {/* <div className="course-info">
        <span className="catg">{title}</span>

    </div> */}
    <div className="learner-course-title">
        <p>{title}</p>
        <button className='btn btn-primary'>View Simulation</button>
        
    </div>
</div>
  )
}

export default ViewSomulationsCard