import React, { Suspense } from 'react';
import api from '../../../../../services/Api/Api';
import Loader from '../../../../shared/loader/loader';
import { v4 as uuidv4 } from 'uuid';
import './courseInfo.scss';
import BlogWriter from 'react-blog-writer';
import axios from 'axios';
import { Modal } from '../../../../shared/modal/modal';
import VimeoPlayer from '../../../../shared/vimeoplayer/vimeoplayer';
// const VimeoPlayer = lazy(() => import("../../../../shared/vimeoplayer/vimeoplayer"));
// const MultiPlayer = lazy(() => import("../../../../shared/vimeoplayer/MultiPlayer"));

export class CourseInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            resMessage: '',
            resType: '',
            // --------
            formdata: [],
            title: '',
            content: [],
            poster: '',
            price: 0,
            isContent: true,
            objectiveCurr: '',
            objectivArr: [],
            contentEdit: '',
            introVideo: '',
            category: [],
            subcategory: [],
            categoryValue: '',

            selectCategoryValue: '',
            selectSubCategoryValue: '',
            api_categories: []
        }
        this.handleFormChange = this.handleFormChange.bind(this);
    }
    componentDidMount() {
        this.getCourseEditForm();
        this.getCategories()
    }
    getCourse() {
        let id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.courseDetails({ courseId: id }).then(res => {
                if (res.data.resCode === 200) {
                    // redirect to details page
                    this.setState(prevState => ({
                        loader: false,
                        title: res.data.data.title,
                        content: res.data.data.content,
                        poster: res.data.data.poster,
                        objectivArr: res.data.data.objectives,
                        introVideo: res.data.data.introVideo,
                        selectCategoryValue: res.data.data.category,

                        formdata: prevState.formdata.map(
                            obj => Object.assign(obj, {
                                value: Array.isArray(res.data.data[obj.name]) ? res.data.data[obj.name].join(', ') : res.data.data[obj.name]
                            }))
                    }))

                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })

    }
    getCourseEditForm() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getForm('courseinfo').then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        formdata: res.data.data.fields,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        this.getCourse();
                        // this.getCategories();
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        reCall: 'getProfileForm'
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })
    }
    getCategories() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getCategories().then(res => {
                if (res.data.resCode === 200) {
                    let modifiCategories = [];
                    let eachCount = 0;
                    res.data.resdata.forEach(categries_item => {
                        api.getSubCategories(categries_item)
                            .then((res) => {
                                modifiCategories.push({ id: categries_item.id, category_name: categries_item.value, subCategory: res.data.resdata })
                                eachCount++;
                            })
                            .catch((e) => console.log("subcategories error", e))
                            .finally(() => {
                                if (eachCount === res.data.resdata.length) {
                                    this.setState({
                                        loader: false,
                                        api_categories: modifiCategories,
                                        resMessage: res.data.resMessage,
                                        resType: res.data.resType
                                    }, () => {
                                        // this.fetchData('/user/getSubCategories', 'subcategory');
                                        let currentFormCategoryIndex = this.state.formdata.map(item => { return item.id }).indexOf('categoryFor');
                                        let cat_value = this.state.formdata[currentFormCategoryIndex].value;
                                        this.setState({
                                            selectCategoryValue: cat_value
                                        })
                                    })
                                }
                            })
                    });

                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })
    }
    setNodes(nodes, keyName) {
        let form_data = [...this.state.formdata];
        form_data[form_data.map(e => { return e.name }).indexOf(keyName)].value = nodes;
        this.setState({
            formdata: form_data
        })
    }

    handleFormChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        this.setState(prevState => ({
            ...prevState,
            formdata: prevState.formdata.map(
                obj => (obj.name === name ? Object.assign(obj, {
                    value: value,
                    error: (() => {
                        if ((obj.validation.isEmptyString && value === '') || (obj.validation.isUndefined && !value) || (obj.validation.isRegex && !this.testRegex(obj.regex, value))) return true;
                        return false;
                    })()
                }) : obj)
            )
        }), () => {

            if (name === 'category') {
                this.setState({
                    selectCategoryValue: value
                })
            }
        })
    }
    testRegex = (reg, val) => {
        let regx = new RegExp(reg, 'i');
        return regx.test(val);
    }
    fileUpload(event, type) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            if (type === 'video') {
                console.log('video upload');
                formData.append('title', this.state.title);
                api.videoUpload(formData).then(res => {
                    if (res.data.resCode === 200) {
                        this.setState({
                            introVideo: res.data.data.id,
                            loader: false
                        }, () => {
                            //introVideo
                            let elem = {
                                target: {
                                    value: this.state.introVideo,
                                    name: 'introVideo'
                                }
                            }
                            this.handleFormChange(elem);
                        })
                    }
                    if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                        this.setState({
                            loader: false,
                            resMessage: res.data.resMessage,
                            resType: res.data.resType
                        })
                    }
                    if (res.data.resCode === 400) {
                        api.logout().then(res => {
                            window.location.replace('/login')
                        })
                    }
                }).catch(err => {
                    this.setState({
                        loader: false,
                        resMessage: "Something went wrong! Please try again",
                        resType: "danger"
                    });
                });
            }
            if (type === 'poster') {
                api.imageupload(formData).then(res => {
                    if (res.data.resCode === 200) {
                        this.setState({
                            poster: res.data.resData.secure_url,
                            loader: false
                        }, () => {
                            let elem = {
                                target: {
                                    value: this.state.poster,
                                    name: 'poster'
                                }
                            }
                            this.handleFormChange(elem);
                        })
                    }
                    if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                        this.setState({
                            loader: false,
                            resMessage: res.data.resMessage,
                            resType: res.data.resType
                        })
                    }
                    if (res.data.resCode === 400) {
                        api.logout().then(res => {
                            window.location.replace('/login')
                        })
                    }
                }).catch(err => {
                    this.setState({
                        loader: false,
                        resMessage: "Something went wrong! Please try again",
                        resType: "danger"
                    });
                });
            }

        });
    }

    updateCourseInfo(e, type = "submit") {
        e.preventDefault();
        let isError = false;
        let courseData = {
            poster: this.state.poster,
            introVideo: this.state.introVideo
        };
        this.state.formdata.map(
            obj => Object.assign(obj, {
                value: obj.value,
                error: (() => {
                    if ((obj.validation.isEmptyString && obj.value === '') || (obj.validation.isUndefined && !obj.value) || (obj.validation.isRegex && !this.testRegex(obj.regex, obj.value))) {
                        return true;
                    } else {
                        return false;
                    }
                })()
            }))

        this.state.formdata.forEach((item) => {
            if (item.error) {
                isError = true;
            }
            if (item.name !== 'poster') {
                let val = item.value;
                if (val === undefined) {
                    val = ''
                }
                courseData[item.name] = val;
            }
            if (item.name === 'introVideo') {
                courseData[item.name] = this.state.introVideo;
            }
            if (item.name === 'keywords') {
                let val = item.value.split(',').map(e => { return e.split(' ')[1] ? e.split(' ')[1] : e });
                courseData.keywords = val;
            }
        });

        // Add Sub Category in Keywords
        if(courseData.subcategory)
        courseData.keywords.push(courseData.subcategory.toLowerCase());

        if (isError === true && type === 'submit') {
            this.setState({
                resMessage: "Please fill up the form correctly, before you submit",
                resType: "danger"
            });
            return;
        }
    
        // return;
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.updateCourse({ data: courseData, courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] }).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                    //this.props.profile(courseData);
                    if (type === 'submit') {
                        window.location.replace(`/account/${window.location.pathname.split('/')[2]}/CourseEdit/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`)
                    }
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })
    }
    createMarkup(content) {
        return { __html: content };
    }
    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            console.log('video res', stateId, res);
            if (!document.getElementById(stateId).src) {
                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err)
        })
    }



    fetchData(url, iname) {
        if (url.includes('getSubCategories')) {
            let cat_id = this.state.category.map(item => { return item.value }).indexOf(this.state.categoryValue);
            if (cat_id === -1) {
                this.setState({
                    loader: false,
                    resMessage: 'Please select a category',
                    resType: 'danger',
                });
                return;
            } else {
                url = url + '/' + this.state.category[cat_id].id
                console.log("get categories = ", url)
            }
        }
        this.setState({
            selectLoader: true,
        }, () => {
            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log("categories id = ", res.data.resdata)
                this.setState({
                    selectLoader: false,
                    [iname]: res.data.resdata,

                }, () => {
                    if (url === "/user/getCategories") {
                        this.setState({
                            subcategory: []
                        })
                    }
                    console.log('this.getCategories.', this.state)
                })
            }).catch(err => {
                this.setState({
                    selectLoader: false,
                    resMessage: "Couldn't fetch data! Please try again.",
                    resType: 'danger',
                    resTitle: 'Alert'
                })
            })
        })

    }
    render() {
        const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;
        const apiDialog = this.state.resMessage !== '' ? <Modal>
            <div className={`text-center alert alert-${this.state.resType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
                <p>{this.state.resMessage}</p>
                <br />
                <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '' }) }}>Okay</button>
            </div>
        </Modal> : null;
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">

                        {/* <div className="col-md-2">
                            <Link to={`/account/${window.location.pathname.split('/')[2]}/CourseEdit/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-primary position-fixed" onClick={this.props.dismiss}> Next <i className="bi bi-arrow-right" /></Link>
                        </div> */}
                        <div className="col-md-12 mt-5">
                            <div className="row">

                                <div className="col-md-2"></div>
                                <div className="col-md-9">
                                    <div className="course-info">
                                        {!this.state.loader &&
                                            <form className="row" onSubmit={(e) => this.updateCourseInfo(e)}>

                                                {this.state.formdata.length > 0 && this.state.api_categories.length > 0 &&
                                                    <>
                                                        {this.state.formdata.map((item, i) => {
                                                            return <div key={i} className={`${item.class} ${item.id === 'introvideoFor' ? 'd-none' : ''}`}>
                                                                <div className={`form-floating mb-3`}>
                                                                    {item.type === 'text' &&
                                                                        <>
                                                                            <input type={item.type} className={`form-control ${item.error ? 'border-danger' : ''}`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value} onChange={item.name !== 'objectives' ? this.handleFormChange : (e) => this.setState({ objectiveCurr: e.target.value })} />
                                                                        </>
                                                                    }
                                                                    {item.type === 'textarea' &&
                                                                        <>
                                                                            <button type="button" className={`btn btn-${this.state.contentEdit !== item.id ? 'primary' : 'success'} px-1 py-0 float-end position-relative`} style={{ zIndex: 99999 }}
                                                                                onClick={() =>
                                                                                    this.setState(prevState => ({
                                                                                        ...prevState,
                                                                                        contentEdit: this.state.contentEdit === '' ? item.id : ''
                                                                                    }))}
                                                                            >
                                                                                {this.state.contentEdit !== item.id && <i className="bi bi-pencil-square"></i>}
                                                                                {this.state.contentEdit === item.id && <span className="text-light"><i className="bi bi-check2-square "></i> Done</span>}
                                                                            </button>
                                                                            {this.state.contentEdit !== item.id && <div className="border bg-white p-4" dangerouslySetInnerHTML={this.createMarkup(item.value)} />}

                                                                            {this.state.contentEdit === item.id &&
                                                                                <BlogWriter finalNodes={(nodes) => this.setNodes(nodes, item.name)} placeHolder={item.value} options={{ noColor: true, noSize: true, noHeading: true, noAlign: true }} />
                                                                            }
                                                                        </>
                                                                    }
                                                                    {item.type === 'keyword' &&
                                                                        <>
                                                                            <textarea className={`form-control`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value} rows="3"
                                                                                onChange={(e)=>{
                                                                                    let formattedValue=e;
                                                                                    formattedValue.target.value=e.target.value.toLowerCase();
                                                                                    this.handleFormChange(formattedValue)}}
                                                                            />
                                                                            {this.state.formdata[this.state.formdata.map(fi => { return fi.name }).indexOf(item.name)].value.split(',').map(keyitem => {
                                                                                return <span key={uuidv4()} className="badge bg-primary text-light me-2 mt-2">{keyitem}</span>
                                                                            })}

                                                                        </>
                                                                    }
                                                                    {item.type === 'select' && Array.isArray(item.options) &&
                                                                        <select className="form-select form-control" name={item.name} value={item.value} onChange={this.handleFormChange}>
                                                                            {item.options.map((option, oi) => {
                                                                                return <option key={i + '-' + oi} value={option.value}>{option.label}</option>
                                                                            })}
                                                                        </select>
                                                                    }
                                                                    {item.id === "categoryFor" &&
                                                                        <select className="form-select form-control" name={item.name} value={item.value}
                                                                            onChange={
                                                                                // (e) => {
                                                                                // console.log("item.value=",item.value)
                                                                                // this.setState({ selectCategoryValue: e.target.value })}
                                                                                this.handleFormChange
                                                                            }>
                                                                            {/* <option value="">Select</option> */}
                                                                            {this.state.api_categories.map((option, oi) => {
                                                                                return <option key={option.id} value={option.category_name}>{option.category_name}</option>
                                                                            })}
                                                                        </select>

                                                                    }
                                                                    {item.id === "subCategoryFor" &&
                                                                        <select className="form-select form-control" name={item.name} value={item.value} onChange={this.handleFormChange} >
                                                                            <option value="">Select</option>
                                                                            {this.state.api_categories
                                                                                .find((x) => x.category_name === this.state.selectCategoryValue)
                                                                                ?.subCategory?.map((category, i) => (
                                                                                    <option key={`${i}-option-value--category`} value={category}>{category}</option>
                                                                                ))}
                                                                            {/* {this.state.api_categories.map((option, oi) => {
                                                                                return <option key={i + '-' + oi} value={option.id }>{option.category_name }</option>
                                                                            })} */}
                                                                        </select>

                                                                    }

                                                                    {item.type === 'radio' &&
                                                                        <>
                                                                            {item.options.map((option, oi) => {
                                                                                return <div key={i + '-' + oi} className="form-check form-check-inline mt-4">
                                                                                    <input className="form-check-input" type={item.type} value={option.value} name={item.name} id={option.value}
                                                                                        checked={item.value === option.value} onChange={this.handleFormChange} />
                                                                                    <label className="form-check-label" htmlFor={option.value}>{option.label}</label>
                                                                                </div>
                                                                            })}
                                                                        </>
                                                                    }
                                                                    {item.type === 'file' && item.id === 'posterFor' &&
                                                                        <React.Fragment key={uuidv4()}>
                                                                            <div className="course-thumb">
                                                                                <img id={item.id} src={this.state.poster}
                                                                                    alt="///"
                                                                                    onError={(e) => {
                                                                                        e.target.style.opacity = 0;
                                                                                    }}
                                                                                    onLoad={(e) => { e.target.style.opacity = 1; }}
                                                                                />
                                                                            </div>
                                                                            <input key={uuidv4()} className="form-control d-none" type="file" id="file" name="file" accept="image/*" onChange={(e) => this.fileUpload(e, 'poster')} />
                                                                            <label htmlFor="file" className="position-abolute fs-4 w-100 d-block" style={{ transform: 'unset', pointerEvents: 'all' }}>
                                                                                <span className="btn btn-primary">Change Poster</span>
                                                                            </label>
                                                                        </React.Fragment>
                                                                    }

                                                                    {/* {item.type === 'file' && item.id === 'introvideoFor' &&
                                                                        <React.Fragment key={uuidv4()}>
                                                                            
                                                                            {this.state.introVideo && this.state.introVideo !== '' &&
                                                                                <Suspense fallback={
                                                                                    <div className="text-center mb-2">
                                                                                        <div className="spinner-border text-primary" role="status">
                                                                                            <span className="visually-hidden">Loading...</span>
                                                                                        </div>
                                                                                    </div>
                                                                                }>
                                                                                    <VimeoPlayer
                                                                                        id={this.state.introVideo}
                                                                                        options={{
                                                                                            width: 320,
                                                                                            byline: false, // vimeo owner's name
                                                                                            color: 'ff0000',
                                                                                        }} />

                                                                                </Suspense>
                                                                                // <MultiPlayer
                                                                                //         id={this.state.introVideo}
                                                                                //         options={{
                                                                                //             width: 320,
                                                                                //             byline: false, // vimeo owner's name
                                                                                //             color: 'ff0000',
                                                                                //         }} />

                                                                            }
                                                                            <input key={uuidv4()} className="form-control d-none" type="file" id="vfile" name="file" accept="video/*" onChange={(e) => this.fileUpload(e, 'video')} />
                                                                            <label htmlFor="vfile" className="position-abolute fs-4 w-100 d-block" style={{ transform: 'unset', pointerEvents: 'all' }}>
                                                                                <span className="btn btn-primary">Intro Video</span>
                                                                            </label>
                                                                        </React.Fragment>
                                                                    } */}
                                                                    <label htmlFor={item.id} style={{ left: item.type === 'textarea' ? '-200px' : '', width: item.type === 'textarea' ? '200px' : '' }} className={`${item.type === 'textarea' ? 'text-primary fw-bold text-end' : ''} ${item.type === 'radio' ? 'moveUp' : ''}`}>{item.label} {item.tagline && item.tagline !== '' && <small>({item.tagline})</small>}</label>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </>
                                                }
                                                <div className="col-md-6 p-2">
                                                    {this.state.introVideo && this.state.introVideo !== '' &&
                                                        <>
                                                            <Suspense fallback={
                                                                <div className="text-center mb-2">
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            }>
                                                                <VimeoPlayer
                                                                    id={this.state.introVideo}
                                                                    options={{
                                                                        // width: 320,
                                                                        byline: false, // vimeo owner's name
                                                                        color: 'ff0000',
                                                                    }} />

                                                            </Suspense>

                                                        </>

                                                    }
                                                    <input key={uuidv4()} className="form-control d-none" type="file" id="vfile" name="file" accept="video/*" onChange={(e) => this.fileUpload(e, 'video')} />
                                                    <label htmlFor="vfile" className="float-end" >
                                                        <span className="btn btn-primary">Intro Video</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-12 py-4">

                                                    <button type="submit" className="btn btn-primary float-end">
                                                        Next  <i className="bi bi-arrow-right" />
                                                    </button>
                                                    <button type="button" onClick={(e) => {
                                                        this.updateCourseInfo(e, 'save')
                                                    }} className="btn btn-primary float-end me-2">
                                                        Save
                                                    </button>

                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
                {apiDialog}
            </React.Fragment>
        )
    }
}